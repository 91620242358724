import React from 'react';
import { Trans, t } from '@lingui/macro';
import Button from '@material-ui/core/Button';
import { withFirebase, FirebaseContextProps } from '../Firebase';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { RouteComponentProps } from 'react-router';
import Page from '../Page';
import FormItem from '../Form/FormItem';
import {
  validateNonEmpty,
  validateNewPassword,
  validateEmail,
  validatePhoneNum,
} from '../Form/Validators';
import { BlankUser, SupportedLang } from 'manybuildlogic';
import { AppState, AddUser } from '../../Redux';
import { connect, ConnectedProps } from 'react-redux';
import { I18n } from '@lingui/react';

interface InitialForm {
  firstName: string;
  lastName: string;
  email: string;
  phoneNum: string;
  companyName: string;
  companyType: string;
  zipCode: string;
  passwordOne: string;
  passwordTwo: string;
  language: SupportedLang;
  error: string;
}

const dispatchProps = {
  addUser: AddUser,
};

const mapStateToProps = (state: AppState) => ({
  lang: state.Auth.lang,
});

const connector = connect(mapStateToProps, dispatchProps);
type SignUpFormReduxProps = ConnectedProps<typeof connector>;

type SignUpFormProps = RouteComponentProps & FirebaseContextProps & SignUpFormReduxProps;

class SignUpFormBase extends React.Component<SignUpFormProps, InitialForm> {
  constructor(props: SignUpFormProps) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNum: '',
      companyName: '',
      companyType: '',
      zipCode: '',
      passwordOne: '',
      passwordTwo: '',
      language: props.lang,
      error: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  public handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const { firebase } = this.props;
    const { passwordOne, passwordTwo, error, ...newUser } = this.state;
    const fullName = newUser.firstName + ' ' + newUser.lastName;
    event.preventDefault();

    if (this.state.error.length > 0) {
      return;
    }

    firebase
      .doCreateUserWithEmailAndPassword(this.state.email, this.state.passwordOne)
      .then(user => {
        if (user.user) {
          firebase
            .addUser({ ...BlankUser, ...newUser, fullName }, user.user.uid)
            .then(ref => {
              if (user.user && firebase.auth.currentUser) {
                this.props.addUser({
                  ...BlankUser,
                  ...newUser,
                  fullName,
                  id: user.user.uid,
                });

                firebase.auth.currentUser
                  .updateProfile({
                    displayName: fullName,
                  })
                  .catch(err => this.setState({ error: err.message }));

                this.props.history.push('/');
              }
            })
            .catch(err => this.handleError(err.code));
        }
      })
      .catch(err => this.handleError(err.code));
  };

  public handleError = (error: string) => {
    console.log(error);
    switch (error) {
      case 'auth/email-already-in-use': {
        this.setState({ error: 'This email is already connected to an account.' });
        break;
      }
      case 'auth/invalid-email': {
        this.setState({ error: 'Not a valid email, please double-check and try again.' });
        break;
      }
      case 'auth/uid-already-exists': {
        this.setState({ error: 'Internal error, please refresh and try again.' });
        break;
      }
      case 'auth/phone-number-already-exists': {
        this.setState({ error: 'This phone number is already connected to an account.' });
        break;
      }
      case 'auth/invalid-display-name': {
        this.setState({ error: 'The display name is empty.' });
        break;
      }
      case 'auth/invalid-password': {
        this.setState({ error: 'Invalid password, must be at least 6 characters in length.' });
        break;
      }
      default: {
        this.setState({ error });
        break;
      }
    }
  };

  public handleChange = (
    event: React.ChangeEvent<HTMLInputElement | { name?: string | undefined; value: unknown }>
  ) => {
    this.setState({
      [event.target.name as keyof InitialForm]: event.target.value,
    } as Pick<InitialForm, keyof InitialForm>);
  };

  public render() {
    console.log(this.state.error);
    return (
      <I18n>
        {({ i18n }) => (
          <Page title={i18n._(t`Register`)}>
            <form onSubmit={this.handleSubmit} style={{ width: '75%', margin: 'auto' }}>
              <FormItem
                fieldId="firstNameField"
                value={this.state.firstName}
                name="firstName"
                label={i18n._(t`First Name`)}
                helper={i18n._(t`We will never share your information.`)}
                update={this.handleChange}
                handleError={this.handleError}
                validate={validateNonEmpty('first name')}
              />

              <FormItem
                fieldId="lastNameField"
                value={this.state.lastName}
                name="lastName"
                label={i18n._(t`Last Name`)}
                helper={i18n._(t`Required`)}
                update={this.handleChange}
                handleError={this.handleError}
                validate={validateNonEmpty('last name')}
              />

              <FormItem
                fieldId="emailField"
                value={this.state.email}
                name="email"
                type="email"
                label={i18n._(t`Email Address`)}
                helper={i18n._(t`Required`)}
                update={this.handleChange}
                handleError={this.handleError}
                validate={validateEmail}
              />
              <FormItem
                fieldId="phoneField"
                name="phoneNum"
                type="tel"
                label={i18n._(t`Telephone Number`)}
                helper={i18n._(t`Please include area code.`)}
                value={this.state.phoneNum}
                update={this.handleChange}
                handleError={this.handleError}
                validate={validatePhoneNum}
              />

              <FormItem
                fieldId="companyNameField"
                name="companyName"
                label={i18n._(t`Company Name`)}
                helper={i18n._(t`Required`)}
                value={this.state.companyName}
                update={this.handleChange}
                handleError={this.handleError}
                validate={validateNonEmpty('company name')}
              />

              <FormItem
                fieldId="companyTypeField"
                name="companyType"
                label={i18n._(t`Company Type`)}
                helper={i18n._(t`Your company's trade.`)}
                value={this.state.companyType}
                update={this.handleChange}
                handleError={this.handleError}
                validate={validateNonEmpty('company type')}
              />

              <FormControl fullWidth style={{ marginTop: '15px' }}>
                <InputLabel htmlFor="lang">
                  <Trans>Language</Trans>
                </InputLabel>
                <Select
                  value={this.state.language}
                  onChange={this.handleChange}
                  name="language"
                  inputProps={{ id: 'lang', name: 'language' }}
                >
                  <MenuItem value={'es'}>Español</MenuItem>
                  <MenuItem value={'en'}>English</MenuItem>
                </Select>
              </FormControl>
              <FormItem
                fieldId="zipCodeField"
                name="zipCode"
                label={i18n._(t`Zip Code`)}
                helper={i18n._(t`Please enter your zip code.`)}
                value={this.state.zipCode}
                update={this.handleChange}
                handleError={this.handleError}
                validate={(val: string) => {
                  if (val.length < 5) {
                    return 'Please enter a full zip code';
                  }
                  return 'OK';
                }}
              />

              <FormItem
                fieldId="passwordOneField"
                name="passwordOne"
                label={i18n._(t`Password`)}
                type="password"
                helper={i18n._(t`10+ characters, including a capital letter and a number.`)}
                value={this.state.passwordOne}
                update={this.handleChange}
                handleError={this.handleError}
                validate={validateNewPassword}
              />

              <FormItem
                fieldId="passwordTwoField"
                name="passwordTwo"
                label={i18n._(t`Confirm Password`)}
                type="password"
                helper=""
                value={this.state.passwordTwo}
                update={this.handleChange}
                handleError={this.handleError}
                validate={(val: string) => {
                  if (
                    val.length !== this.state.passwordOne.length ||
                    val !== this.state.passwordTwo
                  ) {
                    return 'Does not match first password.';
                  }
                  return 'OK';
                }}
              />

              {this.state.error.length > 0 ? (
                <p style={{ color: 'red', textAlign: 'center' }}>{this.state.error}</p>
              ) : null}

              <FormControl fullWidth style={{ marginTop: '15px' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ margin: 'auto' }}
                  disabled={this.state.error.length > 0}
                >
                  <Trans>Register</Trans>
                </Button>
              </FormControl>
            </form>
          </Page>
        )}
      </I18n>
    );
  }
}

export default withFirebase(connector(SignUpFormBase));
