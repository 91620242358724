import React from 'react';
import PrivacyAndTerms from './PrivacyAndTerms';
import logo from '../../assets/MBlogo.png';
import { Instagram, Facebook, LinkedIn } from '@material-ui/icons';
// import Fab from '@material-ui/core/Fab';
// import ScrollTop from './ScrollTop';
// import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import './Footer.css';

class Footer extends React.Component {
  public render(): JSX.Element {
    return (
      <footer>
        {/* <div className='svg-container'>
          <svg
            viewBox='0 0 500 150'
            preserveAspectRatio='none'
            className='wave'>
            <path d={path} className='wave-path'></path>
          </svg>
        </div> */}
        <div className="footer-content">
          <img src={logo as string} alt="manybuild" />
          <div className="footer-text">
            <h5>Location</h5>
            <p>
              ManyBuild Inc <br /> 4045 N Pecos St <br /> Denver, CO 80211
            </p>
          </div>
          <div className="footer-text">
            <h5>Contact Us</h5>
            <p>
              (720)-547-5166 <br /> info@ManyBuildSolutions.com
            </p>
            <a href="https://manybuildsolutions.com/contact">
              <button id="contactButton">Contact</button>
            </a>
          </div>
          <div className="footer-contacts">
            <h5>Keep In Touch</h5>
            <section className="icons">
              {/*<Twitter />*/}
              <a href="https://instagram.com/manybuildinc/">
                <Instagram className="iconPic" />
              </a>
              <a href="https://facebook.com/manybuild/">
                <Facebook className="iconPic" />
              </a>
              <a href="https://www.linkedin.com/company/manybuild-inc/">
                <LinkedIn className="iconPic" />
              </a>
            </section>
          </div>
          <ul className="footer-nav">
            <h5>Learn More</h5>
            <li>
              <a href="https://manybuildsolutions.com/home/english">Home</a>
            </li>
            <li>
              <a href="https://manybuildsolutions.com/about">About</a>
            </li>
            <li>
              <a href="https://manybuildsolutions.com/facts">Facts</a>
            </li>
            <li>
              <a href="https://manybuildsolutions.com/team">Team</a>
            </li>
            <li>
              <a href="https://manybuildsolutions.com/contact">Contact</a>
            </li>
            <li>
              <a href="https://manybuildsolutions.com/contact">Marketplace</a>
            </li>
          </ul>
        </div>
        <div className="footer-bottom">
          <div style={{ textAlign: 'center', position: 'relative' }}>
            <p className="copyright">© Copyright 2021 ManyBuild, Inc. All Rights Reserved.</p>
            <PrivacyAndTerms />
          </div>
          {/*<ScrollTop>
            <Fab size='small' aria-label='scroll back to top' id='arrow-up'>
              <KeyboardArrowUpIcon />
            </Fab>
          </ScrollTop>*/}
        </div>
      </footer>
    );
  }
}

export default Footer;
