import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withFirebase, FirebaseContextProps } from '../Firebase';
import LoadingIcon from '../LoadingIcon';
import { DisplayProject, MakeDisplayProject, FullProject } from 'manybuildlogic';
import { AppState, LoadMarketProjects } from '../../Redux';
import { connect, ConnectedProps } from 'react-redux';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import MarketCard from './MarketCard';
import './Market.css';

const styles = createStyles({
  root: {
    height: '20% !important',
    textAlign: 'center',
  },
  tile: {
    overflow: 'visible',
  },
});

interface MarketState {
  loading: boolean;
}

const mapStateToProps = (state: AppState) => ({
  projects: state.Project.marketProjectIds.map(id => state.Project.marketProjectsById[id]),
  lang: state.Auth.lang,
});

const dispatchProps = {
  LoadMarketProjects,
};

const connector = connect(mapStateToProps, dispatchProps);
type MarketReduxProps = ConnectedProps<typeof connector>;

type MarketProps = MarketReduxProps & FirebaseContextProps & WithStyles<typeof styles>;

class Market extends React.Component<MarketProps, MarketState> {
  constructor(props: WithStyles<typeof styles> & MarketProps) {
    super(props);
    this.state = {
      loading: props.projects.length === 0,
    };
  }

  public componentDidMount() {
    if (this.props.projects.length === 0) {
      this.props.firebase
        .getByQuery('projects', 'public', '==', true)
        .then(data => {
          console.log(data);
          const projects: DisplayProject[] = data.map(proj =>
            MakeDisplayProject(proj as FullProject, this.props.lang)
          );

          this.props.LoadMarketProjects(projects);
          this.setState({ loading: false });
        })
        .catch(err => console.error(err));
    }
  }

  public render() {
    if (this.state.loading) {
      return <LoadingIcon size={80} />;
    }

    const projects = this.props.projects;
    return (
      <Grid container spacing={3} direction="row" justify="space-evenly">
        {projects.map(proj => (
          <MarketCard key={proj.id} proj={proj} lang={this.props.lang} />
        ))}
      </Grid>
    );
  }
}

export default withFirebase(connector(withStyles(styles)(Market)));
