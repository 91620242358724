import mixpanel from 'mixpanel-browser';

const envCheck = true;
// process.env.NODE_ENV === 'production';

export default class Mixpanel {
  public static in = false;
  public static id = false;
  public static init() {
    if (!this.in && envCheck) {
      mixpanel.init('e86301ce35aaaeff3dd45bf47cc87def');
      this.in = true;
    }
  }

  public static identify(userId: string) {
    if (!this.in) {
      this.init();
    }

    if (userId.length > 0 && envCheck) {
      mixpanel.identify(userId);
      mixpanel.track('Login', { id: userId });
      this.id = true;
    }
  }

  public static track(id: string, props?: any) {
    if (!this.in) {
      this.init();
    }
    if (envCheck) mixpanel.track(id, props);
  }
}
