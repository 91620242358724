import React from 'react';
import TopNav from './TopNav';
import Footer from './Footer';
import Mixpanel from '../Mixpanel';
import ErrorBoundary from '../Errors/ErrorBoundary';
import './Page.css';
import { BodyContainer, HeaderContainer } from '../Container';

const Page: React.FC<{ title?: string }> = props => {
  // track referrer from here; if document.referrer contains 'manybuild', ignore it
  Mixpanel.init();
  Mixpanel.track('Page view', { title: props.title });
  return (
    <ErrorBoundary>
      <TopNav />
      <div id="content_container">
        {props.title ? <HeaderContainer title={props.title} /> : null}
        <BodyContainer>{props.children}</BodyContainer>
      </div>
      <Footer />
    </ErrorBoundary>
  );
};

export default Page;
