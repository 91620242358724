import {
  BidActionTypes,
  LOAD_BIDS,
  ADD_BID,
  UPDATE_BID,
  DELETE_BID,
  CLEAR_BIDS,
} from '../Store/Bid';
import { FullBid } from 'manybuildlogic';

export function LoadBids(bids: FullBid[]): BidActionTypes {
  return {
    type: LOAD_BIDS,
    bids,
  };
}

export function AddBid(bid: FullBid): BidActionTypes {
  return {
    type: ADD_BID,
    bid,
  };
}

export function UpdateBid(bid: FullBid): BidActionTypes {
  return {
    type: UPDATE_BID,
    bid,
  };
}

export function DeleteBid(id: string): BidActionTypes {
  return {
    type: DELETE_BID,
    id,
  };
}

export function ClearBids(): BidActionTypes {
  return {
    type: CLEAR_BIDS,
  };
}
