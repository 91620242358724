import React from 'react';
import { useLocation } from 'react-router-dom';
import Mixpanel from '../Mixpanel';
import Page from '../Page';

function useQuery() {
  const referrer = new URLSearchParams(useLocation().search).get('referrer');
  if (referrer) {
    console.log(referrer);
    Mixpanel.track('Referral', { referrer });
  }
}
const ReferralPage: React.FC = () => {
  useQuery();

  return (
    <Page>
      <div style={{ width: '300px', margin: '20px auto' }}>
        <div style={{ marginBottom: '50px' }}>
          <a href="https://play.google.com/store/apps/details?id=com.manybuildnative&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              style={{
                display: 'inline-block',
                overflow: 'hidden',
                borderRadius: '13px',
                width: '290px',
                margin: '0 auto',
              }}
            />
          </a>
        </div>
        <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
          <a
            href="https://apps.apple.com/us/app/manybuild/id1516557059?itsct=apps_box&amp;itscg=30200"
            style={{
              display: 'inline-block',
              overflow: 'hidden',
              borderRadius: '13px',
              width: '250px',
              height: '83px',
            }}
          >
            <img
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?size=250x83&amp;releaseDate=1599350400&h=a4cb2611cdb5e5b3e67a24808f60b3f8"
              alt="Download on the App Store"
              style={{ borderRadius: '13px', width: '250px', height: '83px' }}
            />
          </a>
        </div>
      </div>
    </Page>
  );
};

export default ReferralPage;
