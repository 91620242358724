import {
  REPORT_AUTH_STATUS,
  UserActionTypes,
  UPDATE_USER,
  CLEAR_USER,
  ADD_USER,
  SET_LANGUAGE,
} from '../Store/User';
import { FullUserData, SupportedLang } from 'manybuildlogic';

export function AddUser(user: FullUserData): UserActionTypes {
  return {
    type: ADD_USER,
    user,
  };
}

export function SetLanguage(lang: SupportedLang): UserActionTypes {
  return {
    type: SET_LANGUAGE,
    lang,
  };
}

export function UpdateUser(user: FullUserData): UserActionTypes {
  return {
    type: UPDATE_USER,
    user,
  };
}

export function ClearUser(): UserActionTypes {
  return {
    type: CLEAR_USER,
  };
}

export function ReportAuthStatus(status: boolean): UserActionTypes {
  return {
    type: REPORT_AUTH_STATUS,
    status,
  };
}
