import React from 'react';
import Select, { createFilter } from 'react-select';
import { ValueType } from 'react-select/src/types';
import { OptionType, TradeListCategories, Trade } from 'manybuildlogic';
import { TradeListOptions } from './TradeListOptions';

interface TradeAutocompleteProps {
  updateTrades: (trades: Trade[]) => void;
  trades: Trade[];
  categories?: boolean;
}

const TradeAutocomplete = (props: TradeAutocompleteProps) => {
  const options = props.categories
    ? TradeListCategories.map(category => ({
        label: category,
        value: category,
        trade: { trade: '', category, sortOrder: 0 },
      }))
    : TradeListOptions;

  const values = props.trades.map(trade => ({
    label: props.categories ? trade.category : trade.trade,
    value: props.categories ? trade.category : trade.trade,
    trade,
  }));

  const placeholder = props.categories ? 'Select your trades' : 'Select trades for this project';

  const handleChangeMulti = (value: ValueType<OptionType>) => {
    if (value && Array.isArray(value)) {
      props.updateTrades(value.map(val => val.trade));
    }
  };

  return (
    <div
      style={{
        width: '80%',
        margin: 'auto',
      }}
    >
      <Select
        inputId="react-select-multiple"
        TextFieldProps={{
          label: 'Trades',
          InputLabelProps: {
            htmlFor: 'react-select-multiple',
          },
        }}
        placeholder={placeholder}
        name="Trades"
        options={options}
        value={values}
        onChange={handleChangeMulti}
        isMulti
        filterOption={createFilter({ ignoreAccents: false })}
      />
    </div>
  );
};

export default React.memo(TradeAutocomplete);
