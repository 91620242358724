import React from 'react';
import { Trans } from '@lingui/macro';
import { FirebaseContextProps } from '../Firebase';
import { Link } from 'react-router-dom';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Page from '../Page';
import { UserBidList } from '../Bid';
import { ProjectList } from '../Project';
import EditUserForm from './EditUser';
import { AppState, DeleteProject } from '../../Redux';
import { connect, ConnectedProps } from 'react-redux';

const dispatchProps = {
  deleteProject: DeleteProject,
};

const mapStateToProps = (state: AppState) => ({
  user: state.Auth.user,
});
const connector = connect(mapStateToProps, dispatchProps);
type UserPageReduxProps = ConnectedProps<typeof connector>;

type UserPageProps = FirebaseContextProps & UserPageReduxProps;

/**
 * UserPageState
 * open: edit user dialog open or no
 */
interface UserPageState {
  open: boolean;
}

/**
 * User base class
 */
class UserPage extends React.Component<UserPageProps, UserPageState> {
  /**
   * constructor: sets open to false
   * @param props
   */
  constructor(props: UserPageProps) {
    super(props);
    this.state = {
      open: false,
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  /**
   * handleOpen: opens up edit dialog
   */
  private handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.setState({
      open: true,
    });
  };

  /**
   * handleClose: closes the edit dialog
   */
  private handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.setState({
      open: false,
    });
  };

  /**
   * handleDelete: deletes project with id from props
   * confirms with user before delete
   */
  private handleDelete = (id: string) => (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const sure = window.confirm('Are you sure you want to delete this project?');
    if (sure) {
      this.props.firebase
        .deleteProject(id)
        .then(() => {
          this.props.deleteProject(id);
          alert('Project deleted!');
        })
        .catch(err => console.log(err));
    }
  };

  public render() {
    return (
      <Page title={this.props.user.fullName}>
        <IconButton
          aria-label="Edit User"
          type="button"
          color="primary"
          onClick={this.handleOpen}
          style={{
            display: 'block',
            margin: 'auto',
          }}
        >
          <EditIcon />
        </IconButton>
        {/** Change password link */}
        <h6 style={{ textAlign: 'center' }}>
          <Link to="/change-password">
            <Trans>Change Your Password</Trans>
          </Link>
        </h6>
        <h3 style={{ textAlign: 'center' }}>
          <Trans>Projects</Trans>
        </h3>
        {/** List of user's projects */}
        <ProjectList handleDelete={this.handleDelete} />
        <h3 style={{ textAlign: 'center', marginTop: '25px' }}>
          <Trans>Bids</Trans>
        </h3>
        {/** List of user's bids */}
        <UserBidList />
        {/** Edit user dialog: initially closed */}
        <Dialog
          open={this.state.open}
          aria-labelledby="User edit form"
          aria-describedby="User-edit-form-description"
          onClose={this.handleClose}
        >
          <DialogTitle>
            <Trans>Edit User</Trans>
            <IconButton
              aria-label="Close"
              type="button"
              color="secondary"
              style={{ position: 'absolute', right: '2.5%', top: '1%' }}
              onClick={this.handleClose}
            >
              <CancelIcon />
            </IconButton>
          </DialogTitle>
          <EditUserForm
            closeModal={id => this.setState({ open: false })}
            id={this.props.user.id}
            admin={false}
            user={this.props.user}
          />
        </Dialog>
      </Page>
    );
  }
}

export default connector(UserPage);
