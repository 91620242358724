import {
  ProjectActionTypes,
  ADD_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  LOAD_PROJECTS,
  CLEAR_PROJECTS,
  LOAD_MARKET_PROJECTS,
  CLEAR_MARKET_PROJECTS,
} from '../Store/Project';
import { FullProject, DisplayProject } from 'manybuildlogic';

export function LoadProjects(projects: FullProject[]): ProjectActionTypes {
  return {
    type: LOAD_PROJECTS,
    projects,
  };
}

export function AddProject(project: FullProject): ProjectActionTypes {
  return {
    type: ADD_PROJECT,
    project,
  };
}

export function DeleteProject(id: string): ProjectActionTypes {
  return {
    type: DELETE_PROJECT,
    id,
  };
}

export function UpdateProject(
  id: string,
  project: Partial<FullProject>
): ProjectActionTypes {
  return {
    type: UPDATE_PROJECT,
    id,
    project,
  };
}

export function ClearProjects(): ProjectActionTypes {
  return {
    type: CLEAR_PROJECTS,
  };
}

export function LoadMarketProjects(projects: DisplayProject[]): ProjectActionTypes {
  return {
    type: LOAD_MARKET_PROJECTS,
    projects
  }
}

export function ClearMarketProjects(): ProjectActionTypes {
  return {
    type: CLEAR_MARKET_PROJECTS
  }
}