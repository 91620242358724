import React from 'react';
import { Trans } from '@lingui/macro';
import Toolbar from '@material-ui/core/Toolbar';
import Button, { ButtonProps } from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import AddCircleRounded from '@material-ui/icons/AddCircleRounded';
import AccountCircleRounded from '@material-ui/icons/AccountCircleRounded';
import HomeIcon from '@material-ui/icons/Home';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import Menu from '@material-ui/core/Menu';
import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';
import Mixpanel from '../Mixpanel';
import { AppState } from '../../Redux';
import { FirebaseContextProps, withFirebase } from '../Firebase';
import LogoWhite from '../../assets/ManyBuildWhite.png';
import './Page.css';

interface MenuItemLinkProps extends MenuItemProps {
  button?: true;
  to: string;
}

const UnwrappedMenuItemLink = (
  props: MenuItemLinkProps,
  ref: ((instance: unknown) => void) | React.MutableRefObject<unknown> | null
) => <MenuItem component={Link as any} button={props.button} ref={ref} {...props} />;

const MenuItemLink = React.forwardRef(UnwrappedMenuItemLink);

interface ButtonLinkProps extends ButtonProps {
  to: string;
}

const ButtonLink = (props: ButtonLinkProps) => (
  <Button component={Link as any} {...props} to={props.to} />
);

const TopNav: React.FC = () => (
  <div id="top_nav_container">
    <Toolbar style={{ maxHeight: '48px', minHeight: '52px' }}>
      <ButtonLink to="/">
        <img
          src={LogoWhite}
          style={{
            height: 18,
            borderBottom: 'solid',
            borderColor: '#fbb200',
          }}
          alt="ManyBuild"
        />
      </ButtonLink>
      <div id="topnav_right">
        <TopNavMenu />
      </div>
    </Toolbar>
  </div>
);

const mapStateToProps = (state: AppState) => state.Auth;

const connector = connect(mapStateToProps, {});

type TopNavReduxProps = ConnectedProps<typeof connector>;
type TopNavMenuBaseProps = FirebaseContextProps & TopNavReduxProps;

const TopNavMenuBase: React.FC<TopNavMenuBaseProps> = props => {
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLElement) | null>(null);

  function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = (target: string) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    Mixpanel.track('Menu Click', { target });
    setAnchorEl(null);
  };

  function SignOut(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.preventDefault();
    setAnchorEl(null);
    props.firebase.doSignOut().catch(err => console.log(err));
  }

  return (
    <div id="top_nav_container">
      {props.signedIn ? (
        <React.Fragment>
          <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            <MenuIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose('')}
          >
            <MenuItemLink to="/" onClick={handleClose('Home')}>
              <IconButton>
                <HomeIcon style={{ color: '#000' }} />
              </IconButton>
              <Trans>Home</Trans>
            </MenuItemLink>
            {props.user.paid ? (
              <MenuItemLink to="/new-project" onClick={handleClose('New Project')}>
                <IconButton>
                  <AddCircleRounded style={{ color: '#000' }} />
                </IconButton>
                <Trans>New Project</Trans>
              </MenuItemLink>
            ) : null}
            <MenuItemLink to="/user" onClick={handleClose('User Account')}>
              <IconButton>
                <AccountCircleRounded style={{ color: '#000' }} />
              </IconButton>
              <Trans>User</Trans>
            </MenuItemLink>
            <MenuItemLink to="/" onClick={SignOut}>
              <IconButton>
                <MeetingRoomIcon style={{ color: '#000' }} />
              </IconButton>
              <Trans>Sign Out</Trans>
            </MenuItemLink>
          </Menu>
        </React.Fragment>
      ) : props.authStateReported ? (
        <Toolbar style={{ maxHeight: '48px', minHeight: '48px' }}>
          <ButtonLink style={{ textTransform: 'none' }} to="/sign-up">
            <Trans>REGISTER</Trans>
          </ButtonLink>
          |
          <ButtonLink style={{ textTransform: 'none' }} to="/sign-in">
            <Trans>LOG IN</Trans>
          </ButtonLink>
        </Toolbar>
      ) : null}
    </div>
  );
};

const TopNavMenu = withFirebase(connector(TopNavMenuBase));

export default TopNav;
