import {
  ProjectState,
  ProjectActionTypes,
  ADD_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  LOAD_PROJECTS,
  CLEAR_PROJECTS,
  LOAD_MARKET_PROJECTS,
  CLEAR_MARKET_PROJECTS,
} from '../Store/Project';

const initialState: ProjectState = {
  projectIds: [],
  projectsById: {},
  idMap: {},
  lastUpdated: 0,
  marketProjectIds: [],
  marketProjectsById: {},
};

// uses the firebase id for projectsById & projectIds
// use idMap with hid to get the firebase id corresponding
export function projectReducer(
  state: ProjectState = initialState,
  action: ProjectActionTypes
): ProjectState {
  switch (action.type) {
    case LOAD_PROJECTS: {
      const projectIds = action.projects.map(proj => proj.firebaseId);
      const projectsById = action.projects.reduce(
        (byId, proj) => ({
          ...byId,
          [proj.firebaseId]: proj,
        }),
        {}
      );
      const idMap = action.projects.reduce(
        (ids, proj) => ({
          ...ids,
          [proj.shortId]: proj.firebaseId,
        }),
        {}
      );
      return {
        ...state,
        projectIds: [...projectIds, ...state.projectIds.filter(proj => !projectIds.includes(proj))],
        projectsById: {
          ...state.projectsById,
          ...projectsById,
        },
        idMap: {
          ...state.idMap,
          ...idMap,
        },
        lastUpdated: Date.now(),
      };
    }
    case LOAD_MARKET_PROJECTS: {
      const projectIds = action.projects.map(proj => proj.firebaseId);
      const projectsById = action.projects.reduce(
        (byId, proj) => ({
          ...byId,
          [proj.firebaseId]: proj,
        }),
        {}
      );
      const idMap = action.projects.reduce(
        (ids, proj) => ({
          ...ids,
          [proj.shortId]: proj.firebaseId,
        }),
        {}
      );
      return {
        ...state,
        marketProjectIds: [
          ...projectIds,
          ...state.marketProjectIds.filter(proj => !projectIds.includes(proj)),
        ],
        marketProjectsById: {
          ...state.marketProjectsById,
          ...projectsById,
        },
        idMap: {
          ...state.idMap,
          ...idMap,
        },
        lastUpdated: Date.now(),
      };
    }
    case ADD_PROJECT:
      return {
        ...state,
        projectIds: [
          action.project.firebaseId,
          ...state.projectIds.filter(proj => proj !== action.project.firebaseId),
        ],
        projectsById: {
          ...state.projectsById,
          [action.project.firebaseId]: action.project,
        },
        idMap: {
          ...state.idMap,
          [action.project.shortId]: action.project.firebaseId,
        },
      };
    case UPDATE_PROJECT:
      return {
        ...state,
        projectIds: [action.id, ...state.projectIds.filter(proj => proj !== action.id)],
        projectsById: {
          ...state.projectsById,
          [action.id]: {
            ...state.projectsById[action.id],
            ...action.project,
          },
        },
      };
    case DELETE_PROJECT: {
      const { [action.id]: toDelete, ...projectsById } = state.projectsById;
      const { [toDelete.shortId]: idDelete, ...idMap } = state.idMap;
      return {
        ...state,
        projectIds: state.projectIds.filter(proj => proj !== action.id),
        projectsById,
        idMap,
      };
    }
    case CLEAR_PROJECTS:
      return {
        ...state,
        projectIds: [],
        projectsById: {},
      };
    case CLEAR_MARKET_PROJECTS:
      return {
        ...state,
        marketProjectIds: [],
        marketProjectsById: {},
      };
    default:
      return state;
  }
}
