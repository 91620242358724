export const validateEmail = (email: string) => {
  if (
    email.length < 1 ||
    !email.match(
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    return 'Please enter an email.';
  }
  return 'OK';
};

export const validatePassword = (password: string) => {
  if (password.length < 1) return 'Please enter your password.';
  return 'OK';
};

export const validateNewPassword = (password: string) => {
  if (password.length < 6) {
    return 'At least 6 characters';
  }
  return 'OK';
};

export const validateNonEmpty = (name: string) => (val: string) => {
  if (val.length < 1) {
    return 'Please enter a ' + name;
  }
  return 'OK';
};

export const validatePhoneNum = (num: string) => {
  if (num.length !== 10 || !num.match(/([0-9]+)/)) {
    return 'Please enter phone number with area code';
  }
  return 'OK';
};

export const validateNonReq = (val: string) => 'OK';
