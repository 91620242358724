import React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Page from '../Page';
import { FullBid, } from 'manybuildlogic';
import { withFirebase, FirebaseContextProps } from '../Firebase';
import { BidForm } from '.';
import {AppState, DeleteBid } from '../../Redux';

interface BidRouteProps {
  bidId: string;
}

const dispatchProps = {
  deleteBid: DeleteBid,
};
const baseConnector = connect(null, dispatchProps);
type DetailBidReduxProps = ConnectedProps<typeof baseConnector>;

interface DetailBidOwnProps extends FirebaseContextProps {
  bid: FullBid;
}

type DetailBidProps = DetailBidOwnProps & FirebaseContextProps & DetailBidReduxProps;

interface DetailBidState {
  open: boolean;
}

class DetailBidBase extends React.Component<DetailBidProps, DetailBidState> {
  constructor(props: DetailBidProps) {
    super(props);

    this.state = {
      open: false,
    };
  }

  private handleDelete = (id: string) => {
    const sure = window.confirm('Are you sure you would like to delete this bid?');
    if (sure) {
      this.props.firebase.firestore
        .collection('bids')
        .doc(id)
        .get()
        .then(snap => {
          snap.ref
            .delete()
            .then(() => {
              this.props.deleteBid(id);
              alert('Bid deleted.');
            })
            .catch(err => console.log(err));
        })
        .catch(err => console.log(err));
    }
  };

  public handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.setState({
      open: true,
    });
  };

  public render() {
    const { bid } = this.props;
    return (
      <div style={{ textAlign: 'center' }}>
        <Link to={'/project/' + bid.projectId}>
          <h2>{bid.projectName}</h2>
        </Link>
        <IconButton
          aria-label="Edit User"
          type="button"
          color="primary"
          onClick={this.handleOpen}
          style={{
            display: 'block',
            margin: 'auto',
          }}
        >
          <EditIcon />
        </IconButton>
        <h3>
          <Trans>Bid Amount</Trans>: {bid.bidAmt}
        </h3>
        <h3>
          <Trans>Trades</Trans>: {bid.trades.join(', ')}
        </h3>
        <h3>
          <Trans>Accepted by Project Owner</Trans>:{' '}
          {bid.approved ? <Trans>Yes</Trans> : <Trans>No</Trans>}
        </h3>
        <h3>
          <Trans>Material Included in Bid</Trans>:{' '}
          {bid.materialIncluded ? <Trans>Yes</Trans> : <Trans>No</Trans>}
        </h3>
        {bid.message.length > 1 ? <p>{bid.message}</p> : null}
        <IconButton
          aria-label="Delete"
          type="button"
          color="secondary"
          onClick={event => {
            this.handleDelete(bid.id);
          }}
        >
          <DeleteIcon />
        </IconButton>
        {this.state.open ? (
          <div style={{ width: '60%', margin: 'auto' }}>
            <BidForm
              closeModal={() => this.setState({ open: false })}
              projectId={this.props.bid.projectId}
              projectOwner={this.props.bid.projectOwner}
              projectName={this.props.bid.projectName}
              bid={this.props.bid}
              trades={this.props.bid.trades}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

const DetailBid = withFirebase(baseConnector(DetailBidBase));

const mapStateToProps = (state: AppState) => ({
  bids: state.Bid.bids,
});
const connector = connect(mapStateToProps, {});
type DetailBidPageReduxProps = ConnectedProps<typeof connector>;

type DetailBidPageProps = RouteComponentProps<BidRouteProps> & DetailBidPageReduxProps;

const DetailBidPage: React.FC<DetailBidPageProps> = (props: DetailBidPageProps) => {
  const bidId = props.match.params.bidId;
  const bidArr = props.bids.filter(bid => bid.id === bidId);
  if (bidArr.length === 0) {
    return <Page />;
  }

  return (
    <Page>
      <DetailBid bid={bidArr[0]} />
    </Page>
  );
};

export default connector(DetailBidPage);
