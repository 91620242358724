import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Omit } from 'utility-types';
import Firebase from '.';
import {
  AddUser,
  ClearUser,
  LoadProjects,
  ClearProjects,
  LoadBids,
  ClearBids,
  ReportAuthStatus,
  AddMessage,
  AddMessages,
  ClearChats,
  LoadChats,
  LoadMessages,
} from '../../Redux';

export const FirebaseContext = React.createContext<Firebase | null>(null);

const dispatchProps = {
  AddUser,
  ClearUser,
  LoadProjects,
  ClearProjects,
  LoadBids,
  ClearBids,
  ReportAuthStatus,
  AddMessage,
  AddMessages,
  ClearChats,
  LoadChats,
  LoadMessages,
};

const connector = connect(null, dispatchProps);
export type FirebaseProps = ConnectedProps<typeof connector>;

const FirebaseContextProviderBase: React.FC<FirebaseProps> = props => {
  const fb = new Firebase(props);
  return <FirebaseContext.Provider value={fb}>{props.children}</FirebaseContext.Provider>;
};

export const FirebaseContextProvider = connector(FirebaseContextProviderBase);

export const FirebaseContextConsumer = FirebaseContext.Consumer;
export type ShadowInjected<T1 extends T2, T2> = Omit<T1, keyof T2>;

export interface FirebaseContextProps {
  firebase: Firebase;
}

export const withFirebase = <T extends FirebaseContextProps>(
  WrappedComponent: React.ComponentType<T>
): React.FC<ShadowInjected<T, FirebaseContextProps>> => {
  return function wrapComponent(props: ShadowInjected<T, FirebaseContextProps>) {
    return (
      <FirebaseContextConsumer>
        {val => val && <WrappedComponent {...(props as T)} firebase={val} />}
      </FirebaseContextConsumer>
    );
  };
};
