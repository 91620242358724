import React from 'react';
import { DisplayProject, CoverCard, SupportedLang, DedupeArr } from 'manybuildlogic';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import ImagePlaceholder from '../../assets/placeholder.png';
import Currency from '../Pieces/Currency';

const shortenDescription = (prevString: string) => prevString.substring(0, 130);

const MarketCard: React.FC<{
  proj: DisplayProject;
  lang: SupportedLang;
}> = (props: { proj: DisplayProject; lang: SupportedLang }) => {
  const { proj, lang } = props;
  const categories = [
    ...proj.trades.filter(t => t.category === 'Popular').map(t => t.trade),
    ...DedupeArr(proj.trades.map(t => t.category).filter(t => t !== 'Popular')),
  ];

  return (
    <Grid item xs={12} md={8} lg={3} className="project-card">
      <Link to={'/project/' + proj.shortId} style={{ textDecoration: 'none' }}>
        <Card style={{ height: 550, marginBottom: '15px' }}>
          <CardActionArea>
            <CardContent style={{ height: 60 }} className="cardDescription">
              <Typography gutterBottom style={{ fontSize: 15, fontWeight: 'bold' }}>
                {proj.title}
              </Typography>
              <p>{proj.projectType}</p>
            </CardContent>
            {proj.awarded ? (
              <CardContent style={{ height: 0, padding: 0 }}>
                <Typography
                  style={{
                    fontSize: 35,
                    fontWeight: 'bold',
                    transform: 'rotate(-20deg)',
                    color: 'rgb(251, 178, 0)',
                    zIndex: 100,
                    backgroundColor: '#043268',
                    width: 1000,
                    position: 'relative',
                    paddingLeft: '50%',
                    marginLeft: -80,
                    top: -15,
                    textIndent: '-5%',
                  }}
                >
                  {lang === 'en' ? 'AWARDED' : 'CONTRATADO'}
                  <br />
                  <Currency val={proj.awardValue} />
                </Typography>
              </CardContent>
            ) : null}
            {proj.coverPhoto.length > 0 ? (
              <CardMedia
                component="div"
                image={proj.photos[proj.coverPhoto].processed}
                title={proj.title}
                className={proj.approved ? 'approvedProject' : 'unapprovedProject'}
              />
            ) : (
              <CardMedia
                component="div"
                image={ImagePlaceholder}
                title={'No Image'}
                className={proj.approved ? 'approvedProject' : 'unapprovedProject'}
              />
            )}
          </CardActionArea>
          <CardContent style={{ maxHeight: 140, overflow: 'auto' }} className="cardDescription">
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              style={{ height: 60 }}
            >
              {proj.description.length > 140 ? (
                <p>
                  {shortenDescription(proj.description)}{' '}
                  <span style={{ color: '#000' }}>
                    ... <Trans>Read More</Trans>
                  </span>{' '}
                </p>
              ) : (
                <p>{proj.description}</p>
              )}
            </Typography>
            <Grid container spacing={1} style={{ paddingBottom: '5px' }}>
              {categories.length > 0 ? (
                categories.map(trade => (
                  /*<GridListTile
                            key={trade + proj.description.slice(0, 3)}
                            cols={1}
                            rows={1}
                            classes={{
                              root: this.props.classes.root,
                              tile: this.props.classes.tile
                            }}
                          >
                            <Chip
                              label={trade}
                              style={{ margin: 'auto', overflow: 'visible' }}
                            />
                          </GridListTile>*/
                  <Grid item key={trade + proj.shortId} style={{ textAlign: 'center' }}>
                    <Chip label={trade} style={{ margin: 'auto', overflow: 'visible' }} />
                  </Grid>
                ))
              ) : (
                <p style={{ color: '#0952aa' }}>
                  <Trans>No trades listed</Trans>
                </p>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
};

export default MarketCard;
