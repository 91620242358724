import React from 'react';

export default class ErrorBoundary extends React.Component {
  public componentDidCatch(error: any, info: any) {
    console.log(typeof error);
    console.log(typeof info);
    console.log(error);
    console.log(info);
  }

  public render() {
    return this.props.children;
  }
}
