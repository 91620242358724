import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { FirebaseContextProps, withFirebase } from '../Firebase';
import { History } from 'history';
import { Trans } from '@lingui/macro';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  Bid,
  DefaultNewBid,
  FullBid,
  FullUserData,
  Trade,
} from 'manybuildlogic';
import Mixpanel from '../Mixpanel';
import TradeAutocomplete from '../Trades/TradeAutocomplete';
import {
  AddBid,
  UpdateBid,
} from '../../Redux';

interface BidFormState {
  bid: Bid;
  open: boolean;
}

const dispatchProps = {
  addBid: AddBid,
  updateBid: UpdateBid,
};

const connector = connect(null, dispatchProps);
type BidFormReduxProps = ConnectedProps<typeof connector>;

interface BidFormOwnProps {
  history?: History;
  projectOwner: string;
  projectId: string;
  projectName: string;
  trades: Trade[];
  closeModal: () => void;
  bid?: FullBid;
  user?: FullUserData;
}

type BidFormProps = FirebaseContextProps & BidFormReduxProps & BidFormOwnProps;

class BidForm extends React.Component<BidFormProps, BidFormState> {
  constructor(props: BidFormProps) {
    super(props);

    this.state = {
      bid: props.bid
        ? props.bid
        : props.user
        ? {
            ...DefaultNewBid,
            companyName: props.user.companyName,
            firstName: props.user.firstName,
            lastName: props.user.lastName,
            phoneNum: props.user.phoneNum,
            email: props.user.email,
            trades: props.trades,
          }
        : { ...DefaultNewBid, trades: props.trades },
      open: true,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (this.props.firebase.auth.currentUser) {
      const { uid } = this.props.firebase.auth.currentUser;

      if (this.props.bid) {
        this.props.firebase
          .updateBid({
            ...this.props.bid,
            ...this.state.bid,
          })
          .then(() => {
            if (this.props.bid) {
              Mixpanel.track('Edit bid save', {
                uid,
                projectId: this.props.projectId,
                bidId: this.props.bid.id,
              });
              this.props.updateBid({
                ...this.props.bid,
                ...this.state.bid,
              });
              this.setState({
                open: false,
              });
              this.props.closeModal();
            }
          })
          .catch(err => console.log(err));
      } else {
        const doc = this.props.firebase.firestore.collection('projects').doc();
        const newBid: FullBid = {
          ...this.state.bid,
          createdAt: new Date().valueOf(),
          projectId: this.props.projectId,
          projectOwner: this.props.projectOwner,
          projectName: this.props.projectName,
          ownerId: uid,
          approved: false,
          id: doc.id,
        };

        this.props.firebase
          .createBid(newBid)
          .then(() => {
            Mixpanel.track('New bid save', {
              uid,
              projectId: this.props.projectId,
              bidId: newBid.id,
            });
            this.props.addBid(newBid);
            if (this.props.history) {
              this.props.history.push('/bid/' + newBid.id);
            }
          })
          .catch(err => console.log(err));
      }
    }
  };

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case 'bidAmt':
        const numVal = Number(value);
        this.setState({
          bid: {
            ...this.state.bid,
            bidAmt: numVal,
          },
        });
        break;
      case 'materialIncluded':
        this.setState({
          bid: {
            ...this.state.bid,
            materialIncluded: event.target.checked,
          },
        });
        break;
      default:
        this.setState({
          bid: {
            ...this.state.bid,
            [name as keyof Bid]: value,
          },
        });
    }
  };

  private handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setState({
      open: false,
    });
    this.props.closeModal();
  };

  public render() {
    return (
      <Dialog
        open={this.state.open}
        aria-labelledby="New Bid Form"
        aria-describedby="new-bid-form-description"
        onClose={this.handleClose}
      >
        <DialogTitle>
          {this.props.bid ? <Trans>Edit Bid</Trans> : <Trans>New Bid</Trans>}
          <IconButton
            aria-label="Close"
            type="button"
            color="secondary"
            style={{ position: 'absolute', right: '2.5%', top: '1%' }}
            onClick={this.handleClose}
          >
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={this.handleSubmit}>
          <DialogContent>
            <FormControl fullWidth style={{ marginTop: '5px' }}>
              <TextField
                name="firstName"
                label={<Trans>First Name</Trans>}
                value={this.state.bid.firstName}
                onChange={this.handleChange}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginTop: '5px' }}>
              <TextField
                name="lastName"
                label={<Trans>Last Name</Trans>}
                value={this.state.bid.lastName}
                onChange={this.handleChange}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginTop: '5px' }}>
              <TextField
                name="email"
                type="email"
                label={<Trans>Email</Trans>}
                value={this.state.bid.email}
                onChange={this.handleChange}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginTop: '5px' }}>
              <TextField
                name="phoneNum"
                type="phone"
                label={<Trans>Telephone Number</Trans>}
                value={this.state.bid.phoneNum}
                onChange={this.handleChange}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginTop: '5px' }}>
              <TextField
                name="companyName"
                label={<Trans>Company</Trans>}
                value={this.state.bid.companyName}
                onChange={this.handleChange}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginTop: '5px' }}>
              <TextField
                name="bidAmt"
                type="number"
                label={<Trans>Bid Amount</Trans>}
                value={this.state.bid.bidAmt === 0 ? '' : this.state.bid.bidAmt}
                onChange={this.handleChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">$</InputAdornment>,
                }}
              />
            </FormControl>

            <FormControlLabel
              style={{ marginTop: '10px', marginLeft: '35%' }}
              control={
                <Checkbox
                  checked={this.state.bid.materialIncluded}
                  onChange={this.handleChange}
                  value="Material Included"
                  name="materialIncluded"
                  color="primary"
                />
              }
              label={<Trans>Material Included in Bid</Trans>}
              labelPlacement="start"
            />

            <TradeAutocomplete
              updateTrades={trades => {
                this.setState({
                  bid: {
                    ...this.state.bid,
                    trades,
                  },
                });
              }}
              trades={this.state.bid.trades}
            />

            <FormControl fullWidth style={{ marginTop: '10px' }}>
              <FormLabel htmlFor="msgMultiline">
                <Trans>Extra Message</Trans>
              </FormLabel>
              <TextField
                name="message"
                multiline
                rows="4"
                value={this.state.bid.message}
                onChange={this.handleChange}
              />
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              style={{ margin: 'auto', backgroundColor: '#fbb200' }}
            >
              {this.props.bid ? <Trans>Save Bid</Trans> : <Trans>Create Bid</Trans>}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default withFirebase(connector(BidForm));
