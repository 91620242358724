import React from 'react';
import { Trans, t } from '@lingui/macro';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { withFirebase, FirebaseContextProps } from '../Firebase';
import { RouteComponentProps } from 'react-router';
import { History } from 'history';
import Page from '../Page';
import FormItem from '../Form/FormItem';
import { validatePassword } from '../Form/Validators';
import { I18n } from '@lingui/react';

interface InitialForm {
  currPass: string;
  password: string;
  passwordTwo: string;
  error: string;
}

interface SetPasswordFormProps extends FirebaseContextProps {
  history: History;
  set?: boolean;
}

class SetPasswordFormBase extends React.Component<SetPasswordFormProps, InitialForm> {
  constructor(props: SetPasswordFormProps) {
    super(props);
    this.state = {
      currPass: '',
      password: '',
      passwordTwo: '',
      error: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  public handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (this.state.password === this.state.passwordTwo && this.props.firebase.auth.currentUser) {
      if (this.props.set) {
        this.props.firebase.auth.currentUser
          .updatePassword(this.state.password)
          .then(usercred => {
            alert('Password set!');
            this.props.history.push('/user');
          })
          .catch(err => console.log(err));
      } else {
        this.props.firebase.auth.currentUser
          .updatePassword(this.state.password)
          .then(usercred => {
            alert('Password set!');
            this.props.history.push('/user');
          })
          .catch(err => console.log(err));
      }
    }
  };

  public handleError = (error: string) => {
    this.setState({ error });
  };

  public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [event.target.name as keyof InitialForm]: event.target.value,
    } as Pick<InitialForm, keyof InitialForm>);
  };

  public render() {
    return (
      <I18n>
        {({ i18n }) => (
          <form onSubmit={this.handleSubmit} style={{ width: '75%', margin: 'auto' }}>
            {this.props.set ? (
              <FormItem
                fieldId="currPasswordField"
                value={this.state.currPass}
                name="currPass"
                type="password"
                label={i18n._(t`Current Password`)}
                helper=""
                update={this.handleChange}
                handleError={this.handleError}
                validate={validatePassword}
              />
            ) : null}
            <FormItem
              fieldId="passwordField"
              value={this.state.password}
              name="password"
              type="password"
              label={i18n._(t`New Password`)}
              helper=""
              update={this.handleChange}
              handleError={this.handleError}
              validate={validatePassword}
            />

            <FormItem
              fieldId="passwordTwoField"
              value={this.state.passwordTwo}
              name="passwordTwo"
              type="password"
              label={i18n._(t`Confirm New Password`)}
              helper=""
              update={this.handleChange}
              handleError={this.handleError}
              validate={validatePassword}
            />

            {this.state.error.length > 0 ? (
              <p style={{ color: 'red', textAlign: 'center' }}>{this.state.error}</p>
            ) : null}

            <FormControl fullWidth style={{ marginTop: '15px' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ margin: 'auto' }}
                disabled={this.state.error.length > 0}
              >
                <Trans>Set Password</Trans>
              </Button>
            </FormControl>
          </form>
        )}
      </I18n>
    );
  }
}

const SetPasswordForm = withFirebase(SetPasswordFormBase);

const SetPasswordPage: React.FC<RouteComponentProps> = (props: RouteComponentProps) => (
  <Page title="Set Password">
    <SetPasswordForm history={props.history} />
  </Page>
);
const ChangePasswordPage: React.FC<RouteComponentProps> = (props: RouteComponentProps) => (
  <Page title="Change Password">
    <SetPasswordForm history={props.history} set />
  </Page>
);

export default SetPasswordPage;
export { ChangePasswordPage };
