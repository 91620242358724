import { OptionType } from 'manybuildlogic';
export const TradeListOptions: OptionType[] = [
  {
    label: 'Access Control',
    value: 'Access Control',
    trade: {
      trade: 'Access Control',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Access Control and Intrusion Detection',
    value: 'Access Control and Intrusion Detection',
    trade: {
      trade: 'Access Control and Intrusion Detection',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Access Flooring',
    value: 'Access Flooring',
    trade: {
      trade: 'Access Flooring',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Accessories',
    value: 'Accessories',
    trade: { trade: 'Accessories', category: 'Furnishings' }
  },
  {
    label: 'Acoustical Ceiling Treatment',
    value: 'Acoustical Ceiling Treatment',
    trade: {
      trade: 'Acoustical Ceiling Treatment',
      category: 'Ceiling Wall'
    }
  },
  {
    label: 'Acoustical Suspended Ceilings',
    value: 'Acoustical Suspended Ceilings',
    trade: {
      trade: 'Acoustical Suspended Ceilings',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Acoustical Wall Treatment',
    value: 'Acoustical Wall Treatment',
    trade: {
      trade: 'Acoustical Wall Treatment',
      category: 'Ceiling Wall'
    }
  },
  {
    label: 'Agricultural Equipment',
    value: 'Agricultural Equipment',
    trade: {
      trade: 'Agricultural Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Air-to-Air Energy Recovery',
    value: 'Air-to-Air Energy Recovery',
    trade: {
      trade: 'Air-to-Air Energy Recovery',
      category: 'HVAC'
    }
  },
  {
    label: 'Airfield Lighting',
    value: 'Airfield Lighting',
    trade: { trade: 'Airfield Lighting', category: 'Roadway' }
  },
  {
    label: 'Airfield Signaling and Control Equipment',
    value: 'Airfield Signaling and Control Equipment',
    trade: {
      trade: 'Airfield Signaling and Control Equipment',
      category: 'Roadway'
    }
  },
  {
    label: 'Airfields',
    value: 'Airfields',
    trade: { trade: 'Airfields', category: 'Roadway' }
  },
  {
    label: 'Amusement Park Structures and Equipment',
    value: 'Amusement Park Structures and Equipment',
    trade: {
      trade: 'Amusement Park Structures and Equipment',
      category: 'Recreational'
    }
  },
  {
    label: 'Anchor Tiebacks',
    value: 'Anchor Tiebacks',
    trade: {
      trade: 'Anchor Tiebacks',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Animal Containment',
    value: 'Animal Containment',
    trade: {
      trade: 'Animal Containment',
      category: 'Recreational'
    }
  },
  {
    label: 'Appliance Supplier',
    value: 'Appliance Supplier',
    trade: { trade: 'Appliance Supplier', category: 'Supplier' }
  },
  {
    label: 'Aquariums',
    value: 'Aquariums',
    trade: { trade: 'Aquariums', category: 'Recreational' }
  },
  {
    label: 'Area Lighting',
    value: 'Area Lighting',
    trade: { trade: 'Area Lighting', category: 'Electric' }
  },
  {
    label: 'Arena Football Boards',
    value: 'Arena Football Boards',
    trade: {
      trade: 'Arena Football Boards',
      category: 'Recreational'
    }
  },
  {
    label: 'Asbestos Remediation',
    value: 'Asbestos Remediation',
    trade: {
      trade: 'Asbestos Remediation',
      category: 'Remediation'
    }
  },
  {
    label: 'Athletic Areas',
    value: 'Athletic Areas',
    trade: { trade: 'Athletic Areas', category: 'Recreational' }
  },
  {
    label: 'Athletic Equipment',
    value: 'Athletic Equipment',
    trade: { trade: 'Athletic Equipment', category: 'Equipment' }
  },
  {
    label: 'Athletic Flooring',
    value: 'Athletic Flooring',
    trade: {
      trade: 'Athletic Flooring',
      category: 'Floors Stairs'
    }
  },
  {
    label: 'Athletic and Recreational Court Walls',
    value: 'Athletic and Recreational Court Walls',
    trade: {
      trade: 'Athletic and Recreational Court Walls',
      category: 'Recreational'
    }
  },
  {
    label: 'Athletic and Recreational Special Construction',
    value: 'Athletic and Recreational Special Construction',
    trade: {
      trade: 'Athletic and Recreational Special Construction',
      category: 'Recreational'
    }
  },
  {
    label: 'Athletic, Recreational, and Playfield Areas',
    value: 'Athletic, Recreational, and Playfield Areas',
    trade: {
      trade: 'Athletic, Recreational, and Playfield Areas',
      category: 'Recreational'
    }
  },
  {
    label: 'Audio-Video Communication',
    value: 'Audio-Video Communication',
    trade: {
      trade: 'Audio-Video Communication',
      category: 'Communications'
    }
  },
  {
    label: 'Audio-Video Systems',
    value: 'Audio-Video Systems',
    trade: {
      trade: 'Audio-Video Systems',
      category: 'Communications'
    }
  },
  {
    label: 'Aviation Pavement',
    value: 'Aviation Pavement',
    trade: { trade: 'Aviation Pavement', category: 'Roadway' }
  },
  {
    label: 'Aviation Pavement Appurtenances',
    value: 'Aviation Pavement Appurtenances',
    trade: {
      trade: 'Aviation Pavement Appurtenances',
      category: 'Roadway'
    }
  },
  {
    label: 'Aviation Pavement Curbs and Gutters',
    value: 'Aviation Pavement Curbs and Gutters',
    trade: {
      trade: 'Aviation Pavement Curbs and Gutters',
      category: 'Roadway'
    }
  },
  {
    label: 'Backfill and Compaction',
    value: 'Backfill and Compaction',
    trade: {
      trade: 'Backfill and Compaction',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Baggage Handling Equipment',
    value: 'Baggage Handling Equipment',
    trade: {
      trade: 'Baggage Handling Equipment',
      category: 'Material Moving Handling'
    }
  },
  {
    label: 'Balcony Floor Construction',
    value: 'Balcony Floor Construction',
    trade: {
      trade: 'Balcony Floor Construction',
      category: 'Structure'
    }
  },
  {
    label: 'Battery Equipment',
    value: 'Battery Equipment',
    trade: { trade: 'Battery Equipment', category: 'Electric' }
  },
  {
    label: 'Biological Decontamination',
    value: 'Biological Decontamination',
    trade: {
      trade: 'Biological Decontamination',
      category: 'Remediation'
    }
  },
  {
    label: 'Bird Control Devices',
    value: 'Bird Control Devices',
    trade: {
      trade: 'Bird Control Devices',
      category: 'Exterior Enclosure'
    }
  },
  {
    label: 'Boilermaker',
    value: 'Boilermaker',
    trade: { trade: 'Boilermaker', category: 'Plumbing' }
  },
  {
    label: 'Bored Piles',
    value: 'Bored Piles',
    trade: {
      trade: 'Bored Piles',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Branch Wiring System',
    value: 'Branch Wiring System',
    trade: { trade: 'Branch Wiring System', category: 'Electric' }
  },
  {
    label: 'Branch Wiring for Lighting',
    value: 'Branch Wiring for Lighting',
    trade: {
      trade: 'Branch Wiring for Lighting',
      category: 'Electric'
    }
  },
  {
    label: 'Breathing Air Replenishment Systems',
    value: 'Breathing Air Replenishment Systems',
    trade: {
      trade: 'Breathing Air Replenishment Systems',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Brick Siding',
    value: 'Brick Siding',
    trade: { trade: 'Brick Siding', category: 'Siding' }
  },
  {
    label: 'Bridge Demolition',
    value: 'Bridge Demolition',
    trade: { trade: 'Bridge Demolition', category: 'Demolition' }
  },
  {
    label: 'Building Cleaning',
    value: 'Building Cleaning',
    trade: { trade: 'Building Cleaning', category: 'Cleaning' }
  },
  {
    label: 'Building Demolition',
    value: 'Building Demolition',
    trade: {
      trade: 'Building Demolition',
      category: 'Demolition'
    }
  },
  {
    label: 'Building Envelope Access',
    value: 'Building Envelope Access',
    trade: {
      trade: 'Building Envelope Access',
      category: 'Scaffolding'
    }
  },
  {
    label: 'Building Illumination',
    value: 'Building Illumination',
    trade: {
      trade: 'Building Illumination',
      category: 'Electric'
    }
  },
  {
    label: 'Building Modules',
    value: 'Building Modules',
    trade: {
      trade: 'Building Modules',
      category: 'Special Construction'
    }
  },
  {
    label: 'Building Product Manufacturing and Supply',
    value: 'Building Product Manufacturing and Supply',
    trade: {
      trade: 'Building Product Manufacturing and Supply',
      category: 'Supplier'
    }
  },
  {
    label: 'Building Subdrainage',
    value: 'Building Subdrainage',
    trade: {
      trade: 'Building Subdrainage',
      category: 'Substructure Mitigation'
    }
  },
  {
    label: 'Building Support Plumbing System Supplementary Components',
    value: 'Building Support Plumbing System Supplementary Components',
    trade: {
      trade: 'Building Support Plumbing System Supplementary Components',
      category: 'Plumbing'
    }
  },
  {
    label: 'Building Support Plumbing Systems',
    value: 'Building Support Plumbing Systems',
    trade: {
      trade: 'Building Support Plumbing Systems',
      category: 'Plumbing'
    }
  },
  {
    label: 'Cabinet Maker',
    value: 'Cabinet Maker',
    trade: {
      trade: 'Cabinet Maker',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Caissons',
    value: 'Caissons',
    trade: {
      trade: 'Caissons',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Call Accounting',
    value: 'Call Accounting',
    trade: {
      trade: 'Call Accounting',
      category: 'Communications'
    }
  },
  {
    label: 'Call Management',
    value: 'Call Management',
    trade: {
      trade: 'Call Management',
      category: 'Communications'
    }
  },
  {
    label: 'Canopy Construction',
    value: 'Canopy Construction',
    trade: { trade: 'Canopy Construction', category: 'Structure' }
  },
  {
    label: 'Canopy Roofing',
    value: 'Canopy Roofing',
    trade: { trade: 'Canopy Roofing', category: 'Roof' }
  },
  {
    label: 'Carpet and Upholstery Cleaning',
    value: 'Carpet and Upholstery Cleaning',
    trade: {
      trade: 'Carpet and Upholstery Cleaning',
      category: 'Cleaning'
    }
  },
  {
    label: 'Carpeted Stair Finish',
    value: 'Carpeted Stair Finish',
    trade: {
      trade: 'Carpeted Stair Finish',
      category: 'Floors Stairs'
    }
  },
  {
    label: 'Carpeting',
    value: 'Carpeting',
    trade: { trade: 'Carpeting', category: 'Floors Stairs' }
  },
  {
    label: 'Casework',
    value: 'Casework',
    trade: { trade: 'Casework', category: 'Furnishings' }
  },
  {
    label: 'Cathodic Protection',
    value: 'Cathodic Protection',
    trade: { trade: 'Cathodic Protection', category: 'Electric' }
  },
  {
    label: 'Ceiling Finish Supplementary Components',
    value: 'Ceiling Finish Supplementary Components',
    trade: {
      trade: 'Ceiling Finish Supplementary Components',
      category: 'Ceiling Wall'
    }
  },
  {
    label: 'Ceiling Finishes',
    value: 'Ceiling Finishes',
    trade: { trade: 'Ceiling Finishes', category: 'Ceiling Wall' }
  },
  {
    label: 'Ceiling Painting and Coating',
    value: 'Ceiling Painting and Coating',
    trade: {
      trade: 'Ceiling Painting and Coating',
      category: 'Paint'
    }
  },
  {
    label: 'Ceiling Paneling',
    value: 'Ceiling Paneling',
    trade: { trade: 'Ceiling Paneling', category: 'Ceiling Wall' }
  },
  {
    label: 'Ceiling Suspension Components',
    value: 'Ceiling Suspension Components',
    trade: {
      trade: 'Ceiling Suspension Components',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Central Cooling',
    value: 'Central Cooling',
    trade: { trade: 'Central Cooling', category: 'HVAC' }
  },
  {
    label: 'Chemical Decontamination',
    value: 'Chemical Decontamination',
    trade: {
      trade: 'Chemical Decontamination',
      category: 'Remediation'
    }
  },
  {
    label: 'Chemical-Waste Systems',
    value: 'Chemical-Waste Systems',
    trade: {
      trade: 'Chemical-Waste Systems',
      category: 'Plumbing'
    }
  },
  {
    label: 'Chutes',
    value: 'Chutes',
    trade: {
      trade: 'Chutes',
      category: 'Material Moving Handling'
    }
  },
  {
    label: 'Cladding',
    value: 'Cladding',
    trade: { trade: 'Cladding', category: 'Siding' }
  },
  {
    label: 'Cleaning',
    value: 'Cleaning',
    trade: { trade: 'Cleaning', category: 'Cleaning' }
  },
  {
    label: 'Clearing and Grubbing',
    value: 'Clearing and Grubbing',
    trade: {
      trade: 'Clearing and Grubbing',
      category: 'Site Prep'
    }
  },
  {
    label: 'Cofferdams',
    value: 'Cofferdams',
    trade: {
      trade: 'Cofferdams',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Column Foundations',
    value: 'Column Foundations',
    trade: {
      trade: 'Column Foundations',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Commercial Equipment',
    value: 'Commercial Equipment',
    trade: {
      trade: 'Commercial Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Commercial Laundry and Dry Cleaning Equipment',
    value: 'Commercial Laundry and Dry Cleaning Equipment',
    trade: {
      trade: 'Commercial Laundry and Dry Cleaning Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Communications',
    value: 'Communications',
    trade: { trade: 'Communications', category: 'Communications' }
  },
  {
    label: 'Communications Supplementary Components',
    value: 'Communications Supplementary Components',
    trade: {
      trade: 'Communications Supplementary Components',
      category: 'Communications'
    }
  },
  {
    label: 'Compartments and Cubicles',
    value: 'Compartments and Cubicles',
    trade: {
      trade: 'Compartments and Cubicles',
      category: 'Special Interior'
    }
  },
  {
    label: 'Compressed-Air Systems',
    value: 'Compressed-Air Systems',
    trade: {
      trade: 'Compressed-Air Systems',
      category: 'Plumbing'
    }
  },
  {
    label: 'Concrete Cement Finishing Contracting',
    value: 'Concrete Cement Finishing Contracting',
    trade: {
      trade: 'Concrete Cement Finishing Contracting',
      category: 'Masonry'
    }
  },
  {
    label: 'Concrete Contracting',
    value: 'Concrete Contracting',
    trade: { trade: 'Concrete Contracting', category: 'Masonry' }
  },
  {
    label: 'Concrete Flatwork',
    value: 'Concrete Flatwork',
    trade: { trade: 'Concrete Flatwork', category: 'Masonry' }
  },
  {
    label: 'Concrete Restoration',
    value: 'Concrete Restoration',
    trade: {
      trade: 'Concrete Restoration',
      category: 'Restoration'
    }
  },
  {
    label: 'Construction Dewatering',
    value: 'Construction Dewatering',
    trade: {
      trade: 'Construction Dewatering',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Construction Equipment Manufacturing and Supply',
    value: 'Construction Equipment Manufacturing and Supply',
    trade: {
      trade: 'Construction Equipment Manufacturing and Supply',
      category: 'Supplier'
    }
  },
  {
    label: 'Construction Labor Equipment Operation',
    value: 'Construction Labor Equipment Operation',
    trade: {
      trade: 'Construction Labor Equipment Operation',
      category: 'Labor'
    }
  },
  {
    label: 'Contaminated Site Material Removal',
    value: 'Contaminated Site Material Removal',
    trade: {
      trade: 'Contaminated Site Material Removal',
      category: 'Remediation'
    }
  },
  {
    label: 'Control and Communication Service',
    value: 'Control and Communication Service',
    trade: {
      trade: 'Control and Communication Service',
      category: 'Site Prep'
    }
  },
  {
    label: 'Conveying',
    value: 'Conveying',
    trade: { trade: 'Conveying', category: 'Conveying' }
  },
  {
    label: 'Conveyors',
    value: 'Conveyors',
    trade: {
      trade: 'Conveyors',
      category: 'Material Moving Handling'
    }
  },
  {
    label: 'Cooling System Supplementary Components',
    value: 'Cooling System Supplementary Components',
    trade: {
      trade: 'Cooling System Supplementary Components',
      category: 'HVAC'
    }
  },
  {
    label: 'Cooling Systems',
    value: 'Cooling Systems',
    trade: { trade: 'Cooling Systems', category: 'HVAC' }
  },
  {
    label: 'Covers and Shelters',
    value: 'Covers and Shelters',
    trade: { trade: 'Covers and Shelters', category: 'Landscape' }
  },
  {
    label: 'Cranes',
    value: 'Cranes',
    trade: {
      trade: 'Cranes',
      category: 'Material Moving Handling'
    }
  },
  {
    label: 'Cribbing and Walers',
    value: 'Cribbing and Walers',
    trade: {
      trade: 'Cribbing and Walers',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Culverts',
    value: 'Culverts',
    trade: { trade: 'Culverts', category: 'Utilities' }
  },
  {
    label: 'Dam Demolition',
    value: 'Dam Demolition',
    trade: { trade: 'Dam Demolition', category: 'Demolition' }
  },
  {
    label: 'Data Communication Program and Integration Services',
    value: 'Data Communication Program and Integration Services',
    trade: {
      trade: 'Data Communication Program and Integration Services',
      category: 'Communications'
    }
  },
  {
    label: 'Data Communications',
    value: 'Data Communications',
    trade: {
      trade: 'Data Communications',
      category: 'Communications'
    }
  },
  {
    label: 'Data Communications Hardware',
    value: 'Data Communications Hardware',
    trade: {
      trade: 'Data Communications Hardware',
      category: 'Communications'
    }
  },
  {
    label: 'Data Communications Network Equipment',
    value: 'Data Communications Network Equipment',
    trade: {
      trade: 'Data Communications Network Equipment',
      category: 'Communications'
    }
  },
  {
    label: 'Data Communications Peripheral Data Equipment',
    value: 'Data Communications Peripheral Data Equipment',
    trade: {
      trade: 'Data Communications Peripheral Data Equipment',
      category: 'Communications'
    }
  },
  {
    label: 'Data Communications Software',
    value: 'Data Communications Software',
    trade: {
      trade: 'Data Communications Software',
      category: 'Communications'
    }
  },
  {
    label: 'Decentralized Cooling',
    value: 'Decentralized Cooling',
    trade: { trade: 'Decentralized Cooling', category: 'HVAC' }
  },
  {
    label: 'Decentralized Heating Equipment',
    value: 'Decentralized Heating Equipment',
    trade: {
      trade: 'Decentralized Heating Equipment',
      category: 'HVAC'
    }
  },
  {
    label: 'Decontamination Equipment',
    value: 'Decontamination Equipment',
    trade: {
      trade: 'Decontamination Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Demolition',
    value: 'Demolition',
    trade: { trade: 'Demolition', category: 'Demolition' }
  },
  {
    label: 'Demolition',
    value: 'Demolition',
    trade: { trade: 'Demolition', category: 'Site Prep' }
  },
  {
    label: 'Demountable Athletic Surfaces',
    value: 'Demountable Athletic Surfaces',
    trade: {
      trade: 'Demountable Athletic Surfaces',
      category: 'Recreational'
    }
  },
  {
    label: 'Derricks',
    value: 'Derricks',
    trade: {
      trade: 'Derricks',
      category: 'Material Moving Handling'
    }
  },
  {
    label: 'Detection and Alarm',
    value: 'Detection and Alarm',
    trade: {
      trade: 'Detection and Alarm',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Detention Equipment',
    value: 'Detention Equipment',
    trade: { trade: 'Detention Equipment', category: 'Equipment' }
  },
  {
    label: 'Distributed Audio-Video Communications Systems',
    value: 'Distributed Audio-Video Communications Systems',
    trade: {
      trade: 'Distributed Audio-Video Communications Systems',
      category: 'Communications'
    }
  },
  {
    label: 'Distributed Communications and Monitoring',
    value: 'Distributed Communications and Monitoring',
    trade: {
      trade: 'Distributed Communications and Monitoring',
      category: 'Communications'
    }
  },
  {
    label: 'Distributed Systems',
    value: 'Distributed Systems',
    trade: {
      trade: 'Distributed Systems',
      category: 'Communications'
    }
  },
  {
    label: 'Domestic Water Distribution',
    value: 'Domestic Water Distribution',
    trade: {
      trade: 'Domestic Water Distribution',
      category: 'Plumbing'
    }
  },
  {
    label: 'Domestic Water Distribution Supplementary Components',
    value: 'Domestic Water Distribution Supplementary Components',
    trade: {
      trade: 'Domestic Water Distribution Supplementary Components',
      category: 'Plumbing'
    }
  },
  {
    label: 'Domestic Water Equipment',
    value: 'Domestic Water Equipment',
    trade: {
      trade: 'Domestic Water Equipment',
      category: 'Plumbing'
    }
  },
  {
    label: 'Domestic Water Piping',
    value: 'Domestic Water Piping',
    trade: {
      trade: 'Domestic Water Piping',
      category: 'Plumbing'
    }
  },
  {
    label: 'Doors',
    value: 'Doors',
    trade: { trade: 'Doors', category: 'Structure' }
  },
  {
    label: 'Dredge, Excavating, and Loading Machine Operation',
    value: 'Dredge, Excavating, and Loading Machine Operation',
    trade: {
      trade: 'Dredge, Excavating, and Loading Machine Operation',
      category: 'Material Moving Handling'
    }
  },
  {
    label: 'Driven Piles',
    value: 'Driven Piles',
    trade: {
      trade: 'Driven Piles',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Dumbwaiters',
    value: 'Dumbwaiters',
    trade: { trade: 'Dumbwaiters', category: 'Conveying' }
  },
  {
    label: 'Dumpster Contractor',
    value: 'Dumpster Contractor',
    trade: { trade: 'Dumpster Contractor', category: 'Site Prep' }
  },
  {
    label: 'Earth Dams',
    value: 'Earth Dams',
    trade: { trade: 'Earth Dams', category: 'Earthwork' }
  },
  {
    label: 'Earth Movement Monitoring',
    value: 'Earth Movement Monitoring',
    trade: {
      trade: 'Earth Movement Monitoring',
      category: 'Special Construction'
    }
  },
  {
    label: 'Earth Stripping and Stockpiling',
    value: 'Earth Stripping and Stockpiling',
    trade: {
      trade: 'Earth Stripping and Stockpiling',
      category: 'Site Prep'
    }
  },
  {
    label: 'Educational and Scientific Equipment',
    value: 'Educational and Scientific Equipment',
    trade: {
      trade: 'Educational and Scientific Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Electric Transmission and Distribution',
    value: 'Electric Transmission and Distribution',
    trade: {
      trade: 'Electric Transmission and Distribution',
      category: 'Electric'
    }
  },
  {
    label: 'Electric Utility',
    value: 'Electric Utility',
    trade: { trade: 'Electric Utility', category: 'Utilities' }
  },
  {
    label: 'Electrical',
    value: 'Electrical',
    trade: { trade: 'Electrical', category: 'Electric' }
  },
  {
    label: 'Electrical - Low Voltage',
    value: 'Electrical - Low Voltage',
    trade: {
      trade: 'Electrical - Low Voltage',
      category: 'Electric'
    }
  },
  {
    label: 'Electrical Contracting',
    value: 'Electrical Contracting',
    trade: {
      trade: 'Electrical Contracting',
      category: 'Electric'
    }
  },
  {
    label: 'Electrical Distribution System Instrumentation and Controls',
    value: 'Electrical Distribution System Instrumentation and Controls',
    trade: {
      trade: 'Electrical Distribution System Instrumentation and Controls',
      category: 'Electric'
    }
  },
  {
    label: 'Electrical Rough In',
    value: 'Electrical Rough In',
    trade: { trade: 'Electrical Rough In', category: 'Electric' }
  },
  {
    label: 'Electrical Service',
    value: 'Electrical Service',
    trade: { trade: 'Electrical Service', category: 'Electric' }
  },
  {
    label: 'Electrical Service and Distribution',
    value: 'Electrical Service and Distribution',
    trade: {
      trade: 'Electrical Service and Distribution',
      category: 'Electric'
    }
  },
  {
    label: 'Electrical Service and Distribution Supplementary Components',
    value: 'Electrical Service and Distribution Supplementary Components',
    trade: {
      trade: 'Electrical Service and Distribution Supplementary Components',
      category: 'Electric'
    }
  },
  {
    label: 'Electrical Site Improvements',
    value: 'Electrical Site Improvements',
    trade: {
      trade: 'Electrical Site Improvements',
      category: 'Electric'
    }
  },
  {
    label: 'Electrical Substations',
    value: 'Electrical Substations',
    trade: {
      trade: 'Electrical Substations',
      category: 'Electric'
    }
  },
  {
    label: 'Electrical Switchgear and Protection Devices',
    value: 'Electrical Switchgear and Protection Devices',
    trade: {
      trade: 'Electrical Switchgear and Protection Devices',
      category: 'Electric'
    }
  },
  {
    label: 'Electrical Transformers',
    value: 'Electrical Transformers',
    trade: {
      trade: 'Electrical Transformers',
      category: 'Electric'
    }
  },
  {
    label: 'Electrical Utility Services',
    value: 'Electrical Utility Services',
    trade: {
      trade: 'Electrical Utility Services',
      category: 'Electric'
    }
  },
  {
    label: 'Electrical Utility Services',
    value: 'Electrical Utility Services',
    trade: {
      trade: 'Electrical Utility Services',
      category: 'Utilities'
    }
  },
  {
    label: 'Electronic Detention Monitoring and Control',
    value: 'Electronic Detention Monitoring and Control',
    trade: {
      trade: 'Electronic Detention Monitoring and Control',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Electronic Digital Systems',
    value: 'Electronic Digital Systems',
    trade: {
      trade: 'Electronic Digital Systems',
      category: 'Communications'
    }
  },
  {
    label: 'Electronic Monitoring and Control',
    value: 'Electronic Monitoring and Control',
    trade: {
      trade: 'Electronic Monitoring and Control',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Electronic Personal Protection',
    value: 'Electronic Personal Protection',
    trade: {
      trade: 'Electronic Personal Protection',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Electronic Safety and Security',
    value: 'Electronic Safety and Security',
    trade: {
      trade: 'Electronic Safety and Security',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Electronic Safety and Security Supplementary Components',
    value: 'Electronic Safety and Security Supplementary Components',
    trade: {
      trade: 'Electronic Safety and Security Supplementary Components',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Electronic Surveillance',
    value: 'Electronic Surveillance',
    trade: {
      trade: 'Electronic Surveillance',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Elevating Platforms',
    value: 'Elevating Platforms',
    trade: {
      trade: 'Elevating Platforms',
      category: 'Scaffolding'
    }
  },
  {
    label: 'Elevators',
    value: 'Elevators',
    trade: { trade: 'Elevators', category: 'Conveying' }
  },
  {
    label: 'Embankments',
    value: 'Embankments',
    trade: { trade: 'Embankments', category: 'Earthwork' }
  },
  {
    label: 'Energy (Utility) Service Contracting',
    value: 'Energy (Utility) Service Contracting',
    trade: {
      trade: 'Energy (Utility) Service Contracting',
      category: 'Utilities'
    }
  },
  {
    label: 'Engineered Wood Siding',
    value: 'Engineered Wood Siding',
    trade: { trade: 'Engineered Wood Siding', category: 'Siding' }
  },
  {
    label: 'Entertainment and Recreational Equipment',
    value: 'Entertainment and Recreational Equipment',
    trade: {
      trade: 'Entertainment and Recreational Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Entrance Flooring',
    value: 'Entrance Flooring',
    trade: {
      trade: 'Entrance Flooring',
      category: 'Floors Stairs'
    }
  },
  {
    label: 'Environmental Energy Service',
    value: 'Environmental Energy Service',
    trade: {
      trade: 'Environmental Energy Service',
      category: 'Utilities'
    }
  },
  {
    label: 'Equipment',
    value: 'Equipment',
    trade: { trade: 'Equipment', category: 'Equipment' }
  },
  {
    label: 'Equipment Screens',
    value: 'Equipment Screens',
    trade: { trade: 'Equipment Screens', category: 'Structure' }
  },
  {
    label: 'Equipment and Furnishings',
    value: 'Equipment and Furnishings',
    trade: {
      trade: 'Equipment and Furnishings',
      category: 'Equipment'
    }
  },
  {
    label: 'Erosion & Stormwater Contractor',
    value: 'Erosion & Stormwater Contractor',
    trade: {
      trade: 'Erosion & Stormwater Contractor',
      category: 'Site Prep'
    }
  },
  {
    label: 'Erosion and Sedimentation Controls',
    value: 'Erosion and Sedimentation Controls',
    trade: {
      trade: 'Erosion and Sedimentation Controls',
      category: 'Earthwork'
    }
  },
  {
    label: 'Escalators',
    value: 'Escalators',
    trade: { trade: 'Escalators', category: 'Conveying' }
  },
  {
    label: 'Evaporative Air-Cooling',
    value: 'Evaporative Air-Cooling',
    trade: { trade: 'Evaporative Air-Cooling', category: 'HVAC' }
  },
  {
    label: 'Excavation Support',
    value: 'Excavation Support',
    trade: {
      trade: 'Excavation Support',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Excavation and Fill',
    value: 'Excavation and Fill',
    trade: { trade: 'Excavation and Fill', category: 'Earthwork' }
  },
  {
    label: 'Exhaust Air',
    value: 'Exhaust Air',
    trade: { trade: 'Exhaust Air', category: 'HVAC' }
  },
  {
    label: 'Exterior Balcony Walls and Railings',
    value: 'Exterior Balcony Walls and Railings',
    trade: {
      trade: 'Exterior Balcony Walls and Railings',
      category: 'Exterior Enclosure'
    }
  },
  {
    label: 'Exterior Bulkheads',
    value: 'Exterior Bulkheads',
    trade: { trade: 'Exterior Bulkheads', category: 'Structure' }
  },
  {
    label: 'Exterior Ceilings',
    value: 'Exterior Ceilings',
    trade: { trade: 'Exterior Ceilings', category: 'Structure' }
  },
  {
    label: 'Exterior Door Supplementary Components',
    value: 'Exterior Door Supplementary Components',
    trade: {
      trade: 'Exterior Door Supplementary Components',
      category: 'Exterior Enclosure'
    }
  },
  {
    label: 'Exterior Doors and Grilles',
    value: 'Exterior Doors and Grilles',
    trade: {
      trade: 'Exterior Doors and Grilles',
      category: 'Exterior Enclosure'
    }
  },
  {
    label: 'Exterior Entrance Doors',
    value: 'Exterior Entrance Doors',
    trade: {
      trade: 'Exterior Entrance Doors',
      category: 'Exterior Enclosure'
    }
  },
  {
    label: 'Exterior Fabrications',
    value: 'Exterior Fabrications',
    trade: {
      trade: 'Exterior Fabrications',
      category: 'Exterior Enclosure'
    }
  },
  {
    label: 'Exterior Fixed Grilles and Screens',
    value: 'Exterior Fixed Grilles and Screens',
    trade: {
      trade: 'Exterior Fixed Grilles and Screens',
      category: 'Exterior Enclosure'
    }
  },
  {
    label: 'Exterior Fixed Windows',
    value: 'Exterior Fixed Windows',
    trade: { trade: 'Exterior Fixed Windows', category: 'Glass' }
  },
  {
    label: 'Exterior Fountains',
    value: 'Exterior Fountains',
    trade: { trade: 'Exterior Fountains', category: 'Landscape' }
  },
  {
    label: 'Exterior Gas Lighting',
    value: 'Exterior Gas Lighting',
    trade: {
      trade: 'Exterior Gas Lighting',
      category: 'Landscape'
    }
  },
  {
    label: 'Exterior Gates',
    value: 'Exterior Gates',
    trade: {
      trade: 'Exterior Gates',
      category: 'Exterior Enclosure'
    }
  },
  {
    label: 'Exterior Grilles',
    value: 'Exterior Grilles',
    trade: {
      trade: 'Exterior Grilles',
      category: 'Exterior Enclosure'
    }
  },
  {
    label: 'Exterior Horizontal Enclosures',
    value: 'Exterior Horizontal Enclosures',
    trade: {
      trade: 'Exterior Horizontal Enclosures',
      category: 'Roof'
    }
  },
  {
    label: 'Exterior Lighting Supplementary Components',
    value: 'Exterior Lighting Supplementary Components',
    trade: {
      trade: 'Exterior Lighting Supplementary Components',
      category: 'Electric'
    }
  },
  {
    label: 'Exterior Louvers',
    value: 'Exterior Louvers',
    trade: {
      trade: 'Exterior Louvers',
      category: 'Exterior Enclosure'
    }
  },
  {
    label: 'Exterior Louvers and Vents',
    value: 'Exterior Louvers and Vents',
    trade: {
      trade: 'Exterior Louvers and Vents',
      category: 'Exterior Enclosure'
    }
  },
  {
    label: 'Exterior Opening Protection Devices',
    value: 'Exterior Opening Protection Devices',
    trade: {
      trade: 'Exterior Opening Protection Devices',
      category: 'Exterior Enclosure'
    }
  },
  {
    label: 'Exterior Operating Windows',
    value: 'Exterior Operating Windows',
    trade: {
      trade: 'Exterior Operating Windows',
      category: 'Glass'
    }
  },
  {
    label: 'Exterior Oversize Doors',
    value: 'Exterior Oversize Doors',
    trade: {
      trade: 'Exterior Oversize Doors',
      category: 'Exterior Enclosure'
    }
  },
  {
    label: 'Exterior Painting',
    value: 'Exterior Painting',
    trade: { trade: 'Exterior Painting', category: 'Paint' }
  },
  {
    label: 'Exterior Parking Control Equipment',
    value: 'Exterior Parking Control Equipment',
    trade: {
      trade: 'Exterior Parking Control Equipment',
      category: 'Roadway'
    }
  },
  {
    label: 'Exterior Pedestrian Control Equipment',
    value: 'Exterior Pedestrian Control Equipment',
    trade: {
      trade: 'Exterior Pedestrian Control Equipment',
      category: 'Roadway'
    }
  },
  {
    label: 'Exterior Signage',
    value: 'Exterior Signage',
    trade: { trade: 'Exterior Signage', category: 'Landscape' }
  },
  {
    label: 'Exterior Soffits',
    value: 'Exterior Soffits',
    trade: { trade: 'Exterior Soffits', category: 'Structure' }
  },
  {
    label: 'Exterior Special Function Doors',
    value: 'Exterior Special Function Doors',
    trade: {
      trade: 'Exterior Special Function Doors',
      category: 'Exterior Enclosure'
    }
  },
  {
    label: 'Exterior Special Function Windows',
    value: 'Exterior Special Function Windows',
    trade: {
      trade: 'Exterior Special Function Windows',
      category: 'Glass'
    }
  },
  {
    label: 'Exterior Steps and Ramps',
    value: 'Exterior Steps and Ramps',
    trade: {
      trade: 'Exterior Steps and Ramps',
      category: 'Roadway'
    }
  },
  {
    label: 'Exterior Utility Doors',
    value: 'Exterior Utility Doors',
    trade: {
      trade: 'Exterior Utility Doors',
      category: 'Exterior Enclosure'
    }
  },
  {
    label: 'Exterior Vents',
    value: 'Exterior Vents',
    trade: {
      trade: 'Exterior Vents',
      category: 'Exterior Enclosure'
    }
  },
  {
    label: 'Exterior Vertical Enclosures',
    value: 'Exterior Vertical Enclosures',
    trade: {
      trade: 'Exterior Vertical Enclosures',
      category: 'Structure'
    }
  },
  {
    label: 'Exterior Wall Appurtenances',
    value: 'Exterior Wall Appurtenances',
    trade: {
      trade: 'Exterior Wall Appurtenances',
      category: 'Exterior Enclosure'
    }
  },
  {
    label: 'Exterior Wall Construction',
    value: 'Exterior Wall Construction',
    trade: {
      trade: 'Exterior Wall Construction',
      category: 'Structure'
    }
  },
  {
    label: 'Exterior Wall Interior Skin',
    value: 'Exterior Wall Interior Skin',
    trade: {
      trade: 'Exterior Wall Interior Skin',
      category: 'Structure'
    }
  },
  {
    label: 'Exterior Wall Opening Supplementary Components',
    value: 'Exterior Wall Opening Supplementary Components',
    trade: {
      trade: 'Exterior Wall Opening Supplementary Components',
      category: 'Structure'
    }
  },
  {
    label: 'Exterior Wall Specialties',
    value: 'Exterior Wall Specialties',
    trade: {
      trade: 'Exterior Wall Specialties',
      category: 'Exterior Enclosure'
    }
  },
  {
    label: 'Exterior Wall Supplementary Components',
    value: 'Exterior Wall Supplementary Components',
    trade: {
      trade: 'Exterior Wall Supplementary Components',
      category: 'Structure'
    }
  },
  {
    label: 'Exterior Wall Veneer',
    value: 'Exterior Wall Veneer',
    trade: { trade: 'Exterior Wall Veneer', category: 'Siding' }
  },
  {
    label: 'Exterior Walls',
    value: 'Exterior Walls',
    trade: { trade: 'Exterior Walls', category: 'Structure' }
  },
  {
    label: 'Exterior Window Wall',
    value: 'Exterior Window Wall',
    trade: { trade: 'Exterior Window Wall', category: 'Glass' }
  },
  {
    label: 'Exterior Windows',
    value: 'Exterior Windows',
    trade: { trade: 'Exterior Windows', category: 'Glass' }
  },
  {
    label: 'Fabric Structures',
    value: 'Fabric Structures',
    trade: {
      trade: 'Fabric Structures',
      category: 'Special Construction'
    }
  },
  {
    label: 'Fabricated Exterior Wall Assemblies',
    value: 'Fabricated Exterior Wall Assemblies',
    trade: {
      trade: 'Fabricated Exterior Wall Assemblies',
      category: 'Structure'
    }
  },
  {
    label: 'Fabrication Services and Supply',
    value: 'Fabrication Services and Supply',
    trade: {
      trade: 'Fabrication Services and Supply',
      category: 'Supplier'
    }
  },
  {
    label: 'Facility Distribution Systems Supplementary Components',
    value: 'Facility Distribution Systems Supplementary Components',
    trade: {
      trade: 'Facility Distribution Systems Supplementary Components',
      category: 'HVAC'
    }
  },
  {
    label: 'Facility Fuel Systems',
    value: 'Facility Fuel Systems',
    trade: { trade: 'Facility Fuel Systems', category: 'HVAC' }
  },
  {
    label: 'Facility Grounding',
    value: 'Facility Grounding',
    trade: { trade: 'Facility Grounding', category: 'Electric' }
  },
  {
    label: 'Facility HVAC Distribution Systems',
    value: 'Facility HVAC Distribution Systems',
    trade: {
      trade: 'Facility HVAC Distribution Systems',
      category: 'HVAC'
    }
  },
  {
    label: 'Facility Hydronic Distribution',
    value: 'Facility Hydronic Distribution',
    trade: {
      trade: 'Facility Hydronic Distribution',
      category: 'HVAC'
    }
  },
  {
    label: 'Facility Potable-Water Storage Tanks',
    value: 'Facility Potable-Water Storage Tanks',
    trade: {
      trade: 'Facility Potable-Water Storage Tanks',
      category: 'Plumbing'
    }
  },
  {
    label: 'Facility Power Generation',
    value: 'Facility Power Generation',
    trade: {
      trade: 'Facility Power Generation',
      category: 'Electric'
    }
  },
  {
    label: 'Facility Power Generation Supplementary Components',
    value: 'Facility Power Generation Supplementary Components',
    trade: {
      trade: 'Facility Power Generation Supplementary Components',
      category: 'Electric'
    }
  },
  {
    label: 'Facility Remediation',
    value: 'Facility Remediation',
    trade: {
      trade: 'Facility Remediation',
      category: 'Remediation'
    }
  },
  {
    label: 'Facility Restoration',
    value: 'Facility Restoration',
    trade: {
      trade: 'Facility Restoration',
      category: 'Restoration'
    }
  },
  {
    label: 'Facility Steam Distribution',
    value: 'Facility Steam Distribution',
    trade: {
      trade: 'Facility Steam Distribution',
      category: 'HVAC'
    }
  },
  {
    label: 'Facility Stormwater Drains',
    value: 'Facility Stormwater Drains',
    trade: {
      trade: 'Facility Stormwater Drains',
      category: 'Plumbing'
    }
  },
  {
    label: 'Fence Erection / Construction Fencing',
    value: 'Fence Erection / Construction Fencing',
    trade: {
      trade: 'Fence Erection / Construction Fencing',
      category: 'Site Prep'
    }
  },
  {
    label: 'Fences and Gates',
    value: 'Fences and Gates',
    trade: { trade: 'Fences and Gates', category: 'Landscape' }
  },
  {
    label: 'Fiber Cement Siding',
    value: 'Fiber Cement Siding',
    trade: { trade: 'Fiber Cement Siding', category: 'Siding' }
  },
  {
    label: 'Finish Carpentry Contracting',
    value: 'Finish Carpentry Contracting',
    trade: {
      trade: 'Finish Carpentry Contracting',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Finish Plumbing',
    value: 'Finish Plumbing',
    trade: { trade: 'Finish Plumbing', category: 'Plumbing' }
  },
  {
    label: 'Fire Detection and Alarm',
    value: 'Fire Detection and Alarm',
    trade: {
      trade: 'Fire Detection and Alarm',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Fire Escapes',
    value: 'Fire Escapes',
    trade: { trade: 'Fire Escapes', category: 'Structure' }
  },
  {
    label: 'Fire Extinguisher Accessories',
    value: 'Fire Extinguisher Accessories',
    trade: {
      trade: 'Fire Extinguisher Accessories',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Fire Extinguishers',
    value: 'Fire Extinguishers',
    trade: {
      trade: 'Fire Extinguishers',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Fire Place Installation and Maintenance',
    value: 'Fire Place Installation and Maintenance',
    trade: {
      trade: 'Fire Place Installation and Maintenance',
      category: 'Special Interior'
    }
  },
  {
    label: 'Fire Protection',
    value: 'Fire Protection',
    trade: {
      trade: 'Fire Protection',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Fire Protection Cabinets',
    value: 'Fire Protection Cabinets',
    trade: {
      trade: 'Fire Protection Cabinets',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Fire Protection Specialties',
    value: 'Fire Protection Specialties',
    trade: {
      trade: 'Fire Protection Specialties',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Fire Suppression',
    value: 'Fire Suppression',
    trade: {
      trade: 'Fire Suppression',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Fire Suppression Supplementary Components',
    value: 'Fire Suppression Supplementary Components',
    trade: {
      trade: 'Fire Suppression Supplementary Components',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Fire-Extinguishing',
    value: 'Fire-Extinguishing',
    trade: {
      trade: 'Fire-Extinguishing',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Fireplaces and Stoves',
    value: 'Fireplaces and Stoves',
    trade: {
      trade: 'Fireplaces and Stoves',
      category: 'Special Interior'
    }
  },
  {
    label: 'Fixed Art',
    value: 'Fixed Art',
    trade: { trade: 'Fixed Art', category: 'Furnishings' }
  },
  {
    label: 'Fixed Furnishings',
    value: 'Fixed Furnishings',
    trade: { trade: 'Fixed Furnishings', category: 'Furnishings' }
  },
  {
    label: 'Fixed Multiple Seating',
    value: 'Fixed Multiple Seating',
    trade: {
      trade: 'Fixed Multiple Seating',
      category: 'Furnishings'
    }
  },
  {
    label: 'Flagpoles',
    value: 'Flagpoles',
    trade: { trade: 'Flagpoles', category: 'Landscape' }
  },
  {
    label: 'Flood Lighting',
    value: 'Flood Lighting',
    trade: { trade: 'Flood Lighting', category: 'Electric' }
  },
  {
    label: 'Floor Construction',
    value: 'Floor Construction',
    trade: { trade: 'Floor Construction', category: 'Structure' }
  },
  {
    label: 'Floor Construction Supplementary Components',
    value: 'Floor Construction Supplementary Components',
    trade: {
      trade: 'Floor Construction Supplementary Components',
      category: 'Structure'
    }
  },
  {
    label: 'Floor Decks, Slabs, and Toppings',
    value: 'Floor Decks, Slabs, and Toppings',
    trade: {
      trade: 'Floor Decks, Slabs, and Toppings',
      category: 'Structure'
    }
  },
  {
    label: 'Floor Sockets',
    value: 'Floor Sockets',
    trade: { trade: 'Floor Sockets', category: 'Recreational' }
  },
  {
    label: 'Floor Structural Frame',
    value: 'Floor Structural Frame',
    trade: {
      trade: 'Floor Structural Frame',
      category: 'Structure'
    }
  },
  {
    label: 'Flooring',
    value: 'Flooring',
    trade: { trade: 'Flooring', category: 'Floors Stairs' }
  },
  {
    label: 'Flooring Supplementary Components',
    value: 'Flooring Supplementary Components',
    trade: {
      trade: 'Flooring Supplementary Components',
      category: 'Floors Stairs'
    }
  },
  {
    label: 'Flooring Treatment',
    value: 'Flooring Treatment',
    trade: {
      trade: 'Flooring Treatment',
      category: 'Floors Stairs'
    }
  },
  {
    label: 'Fluid-Applied Flooring',
    value: 'Fluid-Applied Flooring',
    trade: {
      trade: 'Fluid-Applied Flooring',
      category: 'Floors Stairs'
    }
  },
  {
    label: 'Foodservice Equipment',
    value: 'Foodservice Equipment',
    trade: {
      trade: 'Foodservice Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Foundation Anchors',
    value: 'Foundation Anchors',
    trade: {
      trade: 'Foundation Anchors',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Foundation Drainage',
    value: 'Foundation Drainage',
    trade: {
      trade: 'Foundation Drainage',
      category: 'Substructure Mitigation'
    }
  },
  {
    label: 'Foundation Preparation',
    value: 'Foundation Preparation',
    trade: {
      trade: 'Foundation Preparation',
      category: 'Site Prep'
    }
  },
  {
    label: 'Foundations',
    value: 'Foundations',
    trade: {
      trade: 'Foundations',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Fuel Cells',
    value: 'Fuel Cells',
    trade: { trade: 'Fuel Cells', category: 'Electric' }
  },
  {
    label: 'Fuel Piping',
    value: 'Fuel Piping',
    trade: { trade: 'Fuel Piping', category: 'HVAC' }
  },
  {
    label: 'Fuel Pumps',
    value: 'Fuel Pumps',
    trade: { trade: 'Fuel Pumps', category: 'HVAC' }
  },
  {
    label: 'Fuel Storage Tanks',
    value: 'Fuel Storage Tanks',
    trade: { trade: 'Fuel Storage Tanks', category: 'HVAC' }
  },
  {
    label: 'Fuel-Gas Detection and Alarm',
    value: 'Fuel-Gas Detection and Alarm',
    trade: {
      trade: 'Fuel-Gas Detection and Alarm',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Fuel-Oil Detection and Alarm',
    value: 'Fuel-Oil Detection and Alarm',
    trade: {
      trade: 'Fuel-Oil Detection and Alarm',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Furnishings',
    value: 'Furnishings',
    trade: { trade: 'Furnishings', category: 'Furnishings' }
  },
  {
    label: 'Furniture',
    value: 'Furniture',
    trade: { trade: 'Furniture', category: 'Furnishings' }
  },
  {
    label: 'Furniture Supplier',
    value: 'Furniture Supplier',
    trade: { trade: 'Furniture Supplier', category: 'Supplier' }
  },
  {
    label: 'Gabions',
    value: 'Gabions',
    trade: { trade: 'Gabions', category: 'Earthwork' }
  },
  {
    label: 'Garage Doors',
    value: 'Garage Doors',
    trade: {
      trade: 'Garage Doors',
      category: 'Exterior Enclosure'
    }
  },
  {
    label: 'Gas Systems',
    value: 'Gas Systems',
    trade: { trade: 'Gas Systems', category: 'Plumbing' }
  },
  {
    label: 'Gas Utility',
    value: 'Gas Utility',
    trade: { trade: 'Gas Utility', category: 'Utilities' }
  },
  {
    label: 'General Concrete Contracting',
    value: 'General Concrete Contracting',
    trade: {
      trade: 'General Concrete Contracting',
      category: 'Masonry'
    }
  },
  {
    label: 'General Construction Labor',
    value: 'General Construction Labor',
    trade: {
      trade: 'General Construction Labor',
      category: 'Labor'
    }
  },
  {
    label: 'General Purpose Electrical Power',
    value: 'General Purpose Electrical Power',
    trade: {
      trade: 'General Purpose Electrical Power',
      category: 'Electric'
    }
  },
  {
    label: 'General Purpose Electrical Power Supplementary Components',
    value: 'General Purpose Electrical Power Supplementary Components',
    trade: {
      trade: 'General Purpose Electrical Power Supplementary Components',
      category: 'Electric'
    }
  },
  {
    label: 'General Service Compressed-Air',
    value: 'General Service Compressed-Air',
    trade: {
      trade: 'General Service Compressed-Air',
      category: 'Plumbing'
    }
  },
  {
    label: 'Geodesic Structures',
    value: 'Geodesic Structures',
    trade: {
      trade: 'Geodesic Structures',
      category: 'Special Construction'
    }
  },
  {
    label: 'Geothermal Service',
    value: 'Geothermal Service',
    trade: { trade: 'Geothermal Service', category: 'Utilities' }
  },
  {
    label: 'Glass Doors',
    value: 'Glass Doors',
    trade: { trade: 'Glass Doors', category: 'Glass' }
  },
  {
    label: 'Glazing Contracting - Other',
    value: 'Glazing Contracting - Other',
    trade: {
      trade: 'Glazing Contracting - Other',
      category: 'Glass'
    }
  },
  {
    label: 'Grade Beams',
    value: 'Grade Beams',
    trade: {
      trade: 'Grade Beams',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Grading',
    value: 'Grading',
    trade: { trade: 'Grading', category: 'Earthwork' }
  },
  {
    label: 'Gray Water Systems',
    value: 'Gray Water Systems',
    trade: { trade: 'Gray Water Systems', category: 'Plumbing' }
  },
  {
    label: 'Ground Freezing',
    value: 'Ground Freezing',
    trade: {
      trade: 'Ground Freezing',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Grounds Maintenance Workers',
    value: 'Grounds Maintenance Workers',
    trade: {
      trade: 'Grounds Maintenance Workers',
      category: 'Landscape'
    }
  },
  {
    label: 'Gutters',
    value: 'Gutters',
    trade: { trade: 'Gutters', category: 'Roof' }
  },
  {
    label: 'HVAC Air Cleaning',
    value: 'HVAC Air Cleaning',
    trade: { trade: 'HVAC Air Cleaning', category: 'HVAC' }
  },
  {
    label: 'HVAC Air Distribution',
    value: 'HVAC Air Distribution',
    trade: { trade: 'HVAC Air Distribution', category: 'HVAC' }
  },
  {
    label: 'HVAC Contracting',
    value: 'HVAC Contracting',
    trade: { trade: 'HVAC Contracting', category: 'HVAC' }
  },
  {
    label: 'Hazardous Material Abatement',
    value: 'Hazardous Material Abatement',
    trade: {
      trade: 'Hazardous Material Abatement',
      category: 'Site Prep'
    }
  },
  {
    label: 'Hazardous Materials Remediation',
    value: 'Hazardous Materials Remediation',
    trade: {
      trade: 'Hazardous Materials Remediation',
      category: 'Remediation'
    }
  },
  {
    label: 'Hazardous Waste Drum Handling',
    value: 'Hazardous Waste Drum Handling',
    trade: {
      trade: 'Hazardous Waste Drum Handling',
      category: 'Remediation'
    }
  },
  {
    label: 'Healthcare Communications and Monitoring',
    value: 'Healthcare Communications and Monitoring',
    trade: {
      trade: 'Healthcare Communications and Monitoring',
      category: 'Communications'
    }
  },
  {
    label: 'Healthcare Equipment',
    value: 'Healthcare Equipment',
    trade: {
      trade: 'Healthcare Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Heat Generation',
    value: 'Heat Generation',
    trade: { trade: 'Heat Generation', category: 'HVAC' }
  },
  {
    label: 'Heating System Supplementary Components',
    value: 'Heating System Supplementary Components',
    trade: {
      trade: 'Heating System Supplementary Components',
      category: 'HVAC'
    }
  },
  {
    label: 'Heating Systems',
    value: 'Heating Systems',
    trade: { trade: 'Heating Systems', category: 'HVAC' }
  },
  {
    label: 'Heating, Ventilation and Air Conditioning',
    value: 'Heating, Ventilation and Air Conditioning',
    trade: {
      trade: 'Heating, Ventilation and Air Conditioning',
      category: 'HVAC'
    }
  },
  {
    label: 'Heating, Ventilation, and Air Conditioning (HVAC)',
    value: 'Heating, Ventilation, and Air Conditioning (HVAC)',
    trade: {
      trade: 'Heating, Ventilation, and Air Conditioning (HVAC)',
      category: 'HVAC'
    }
  },
  {
    label: 'High Voltage - more than 1000 volts',
    value: 'High Voltage - more than 1000 volts',
    trade: {
      trade: 'High Voltage - more than 1000 volts',
      category: 'Electric'
    }
  },
  {
    label: 'Hoist and Winch Operation',
    value: 'Hoist and Winch Operation',
    trade: {
      trade: 'Hoist and Winch Operation',
      category: 'Material Moving Handling'
    }
  },
  {
    label: 'Hoists',
    value: 'Hoists',
    trade: {
      trade: 'Hoists',
      category: 'Material Moving Handling'
    }
  },
  {
    label: 'Home Appliance Installation and Maintenance',
    value: 'Home Appliance Installation and Maintenance',
    trade: {
      trade: 'Home Appliance Installation and Maintenance',
      category: 'Special Interior'
    }
  },
  {
    label: 'Home Theater Installers',
    value: 'Home Theater Installers',
    trade: {
      trade: 'Home Theater Installers',
      category: 'Special Interior'
    }
  },
  {
    label: 'Horizontal Conveying',
    value: 'Horizontal Conveying',
    trade: {
      trade: 'Horizontal Conveying',
      category: 'Conveying'
    }
  },
  {
    label: 'Horizontal Enclosure Supplementary Components',
    value: 'Horizontal Enclosure Supplementary Components',
    trade: {
      trade: 'Horizontal Enclosure Supplementary Components',
      category: 'Roof'
    }
  },
  {
    label: 'Horizontal Opening Supplementary Components',
    value: 'Horizontal Opening Supplementary Components',
    trade: {
      trade: 'Horizontal Opening Supplementary Components',
      category: 'Roof'
    }
  },
  {
    label: 'Horizontal Openings',
    value: 'Horizontal Openings',
    trade: { trade: 'Horizontal Openings', category: 'Roof' }
  },
  {
    label: 'Horizontal Waterproofing Membrane',
    value: 'Horizontal Waterproofing Membrane',
    trade: {
      trade: 'Horizontal Waterproofing Membrane',
      category: 'Roof'
    }
  },
  {
    label: 'Horticultural Equipment',
    value: 'Horticultural Equipment',
    trade: {
      trade: 'Horticultural Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Hospitality Equipment',
    value: 'Hospitality Equipment',
    trade: {
      trade: 'Hospitality Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Hydroligical Control (Dewatering)',
    value: 'Hydroligical Control (Dewatering)',
    trade: {
      trade: 'Hydroligical Control (Dewatering)',
      category: 'Site Prep'
    }
  },
  {
    label: 'Ice Rinks',
    value: 'Ice Rinks',
    trade: { trade: 'Ice Rinks', category: 'Recreational' }
  },
  {
    label: 'Indoor Soccer Boards',
    value: 'Indoor Soccer Boards',
    trade: {
      trade: 'Indoor Soccer Boards',
      category: 'Recreational'
    }
  },
  {
    label: 'Industrial Truck and Tractor Operation',
    value: 'Industrial Truck and Tractor Operation',
    trade: {
      trade: 'Industrial Truck and Tractor Operation',
      category: 'Material Moving Handling'
    }
  },
  {
    label: 'Information Specialties',
    value: 'Information Specialties',
    trade: {
      trade: 'Information Specialties',
      category: 'Special Interior'
    }
  },
  {
    label: 'Infrastructure Demolition',
    value: 'Infrastructure Demolition',
    trade: {
      trade: 'Infrastructure Demolition',
      category: 'Site Prep'
    }
  },
  {
    label: 'Institutional Equipment',
    value: 'Institutional Equipment',
    trade: {
      trade: 'Institutional Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Insulating',
    value: 'Insulating',
    trade: { trade: 'Insulating', category: 'Insulation' }
  },
  {
    label: 'Insulating Contracting',
    value: 'Insulating Contracting',
    trade: {
      trade: 'Insulating Contracting',
      category: 'Insulation'
    }
  },
  {
    label: 'Integrated Automation',
    value: 'Integrated Automation',
    trade: {
      trade: 'Integrated Automation',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Integrated Automation Control of Communication Systems',
    value: 'Integrated Automation Control of Communication Systems',
    trade: {
      trade: 'Integrated Automation Control of Communication Systems',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Integrated Automation Control of Conveying Equipment',
    value: 'Integrated Automation Control of Conveying Equipment',
    trade: {
      trade: 'Integrated Automation Control of Conveying Equipment',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Integrated Automation Control of Electrical Systems',
    value: 'Integrated Automation Control of Electrical Systems',
    trade: {
      trade: 'Integrated Automation Control of Electrical Systems',
      category: 'Detection Alarm'
    }
  },
  {
    label:
      'Integrated Automation Control of Electronic Safety and Security Systems',
    value:
      'Integrated Automation Control of Electronic Safety and Security Systems',
    trade: {
      trade:
        'Integrated Automation Control of Electronic Safety and Security Systems',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Integrated Automation Control of Equipment',
    value: 'Integrated Automation Control of Equipment',
    trade: {
      trade: 'Integrated Automation Control of Equipment',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Integrated Automation Control of Fire-Suppression Systems',
    value: 'Integrated Automation Control of Fire-Suppression Systems',
    trade: {
      trade: 'Integrated Automation Control of Fire-Suppression Systems',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Integrated Automation Control of HVAC Systems',
    value: 'Integrated Automation Control of HVAC Systems',
    trade: {
      trade: 'Integrated Automation Control of HVAC Systems',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Integrated Automation Control of Plumbing Systems',
    value: 'Integrated Automation Control of Plumbing Systems',
    trade: {
      trade: 'Integrated Automation Control of Plumbing Systems',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Integrated Automation Facility Controls',
    value: 'Integrated Automation Facility Controls',
    trade: {
      trade: 'Integrated Automation Facility Controls',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Integrated Automation Supplementary Components',
    value: 'Integrated Automation Supplementary Components',
    trade: {
      trade: 'Integrated Automation Supplementary Components',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Integrated Construction',
    value: 'Integrated Construction',
    trade: {
      trade: 'Integrated Construction',
      category: 'Special Construction'
    }
  },
  {
    label: 'Interior Access Doors and Panels',
    value: 'Interior Access Doors and Panels',
    trade: {
      trade: 'Interior Access Doors and Panels',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Coiling Doors',
    value: 'Interior Coiling Doors',
    trade: {
      trade: 'Interior Coiling Doors',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Construction',
    value: 'Interior Construction',
    trade: {
      trade: 'Interior Construction',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Demountable Partitions',
    value: 'Interior Demountable Partitions',
    trade: {
      trade: 'Interior Demountable Partitions',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Door Supplementary Components',
    value: 'Interior Door Supplementary Components',
    trade: {
      trade: 'Interior Door Supplementary Components',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Doors',
    value: 'Interior Doors',
    trade: {
      trade: 'Interior Doors',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Entrance Doors',
    value: 'Interior Entrance Doors',
    trade: {
      trade: 'Interior Entrance Doors',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Fabrications',
    value: 'Interior Fabrications',
    trade: {
      trade: 'Interior Fabrications',
      category: 'Ceiling Wall'
    }
  },
  {
    label: 'Interior Finishes',
    value: 'Interior Finishes',
    trade: {
      trade: 'Interior Finishes',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Fixed Partitions',
    value: 'Interior Fixed Partitions',
    trade: {
      trade: 'Interior Fixed Partitions',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Fixed Windows',
    value: 'Interior Fixed Windows',
    trade: { trade: 'Interior Fixed Windows', category: 'Glass' }
  },
  {
    label: 'Interior Folding Doors',
    value: 'Interior Folding Doors',
    trade: {
      trade: 'Interior Folding Doors',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Fountains',
    value: 'Interior Fountains',
    trade: {
      trade: 'Interior Fountains',
      category: 'Recreational'
    }
  },
  {
    label: 'Interior Gas Lighting',
    value: 'Interior Gas Lighting',
    trade: {
      trade: 'Interior Gas Lighting',
      category: 'Plumbing'
    }
  },
  {
    label: 'Interior Gates',
    value: 'Interior Gates',
    trade: {
      trade: 'Interior Gates',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Glazed Partitions',
    value: 'Interior Glazed Partitions',
    trade: {
      trade: 'Interior Glazed Partitions',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Grilles',
    value: 'Interior Grilles',
    trade: {
      trade: 'Interior Grilles',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Grilles and Gates',
    value: 'Interior Grilles and Gates',
    trade: {
      trade: 'Interior Grilles and Gates',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Louvers',
    value: 'Interior Louvers',
    trade: {
      trade: 'Interior Louvers',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Operable Partitions',
    value: 'Interior Operable Partitions',
    trade: {
      trade: 'Interior Operable Partitions',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Operating Windows',
    value: 'Interior Operating Windows',
    trade: {
      trade: 'Interior Operating Windows',
      category: 'Glass'
    }
  },
  {
    label: 'Interior Painting',
    value: 'Interior Painting',
    trade: { trade: 'Interior Painting', category: 'Paint' }
  },
  {
    label: 'Interior Panel Doors',
    value: 'Interior Panel Doors',
    trade: {
      trade: 'Interior Panel Doors',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Parking Control Equipment',
    value: 'Interior Parking Control Equipment',
    trade: {
      trade: 'Interior Parking Control Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Interior Partition Supplementary Components',
    value: 'Interior Partition Supplementary Components',
    trade: {
      trade: 'Interior Partition Supplementary Components',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Partitions',
    value: 'Interior Partitions',
    trade: {
      trade: 'Interior Partitions',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Pedestrian Control Equipment',
    value: 'Interior Pedestrian Control Equipment',
    trade: {
      trade: 'Interior Pedestrian Control Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Interior Railings and Handrails',
    value: 'Interior Railings and Handrails',
    trade: {
      trade: 'Interior Railings and Handrails',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Screens',
    value: 'Interior Screens',
    trade: {
      trade: 'Interior Screens',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Sliding Doors',
    value: 'Interior Sliding Doors',
    trade: {
      trade: 'Interior Sliding Doors',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Special Function Doors',
    value: 'Interior Special Function Doors',
    trade: {
      trade: 'Interior Special Function Doors',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Special Function Windows',
    value: 'Interior Special Function Windows',
    trade: {
      trade: 'Interior Special Function Windows',
      category: 'Glass'
    }
  },
  {
    label: 'Interior Specialties',
    value: 'Interior Specialties',
    trade: {
      trade: 'Interior Specialties',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Swinging Doors',
    value: 'Interior Swinging Doors',
    trade: {
      trade: 'Interior Swinging Doors',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Trim',
    value: 'Interior Trim',
    trade: {
      trade: 'Interior Trim',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Interior Water Features',
    value: 'Interior Water Features',
    trade: {
      trade: 'Interior Water Features',
      category: 'Recreational'
    }
  },
  {
    label: 'Interior Window Supplementary Components',
    value: 'Interior Window Supplementary Components',
    trade: {
      trade: 'Interior Window Supplementary Components',
      category: 'Glass'
    }
  },
  {
    label: 'Interior Windows',
    value: 'Interior Windows',
    trade: { trade: 'Interior Windows', category: 'Glass' }
  },
  {
    label: 'Interiors',
    value: 'Interiors',
    trade: {
      trade: 'Interiors',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Intrusion Detection',
    value: 'Intrusion Detection',
    trade: {
      trade: 'Intrusion Detection',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Iron Work and Welding Contracting',
    value: 'Iron Work and Welding Contracting',
    trade: {
      trade: 'Iron Work and Welding Contracting',
      category: 'Welding'
    }
  },
  {
    label: 'Iron Working and Welding',
    value: 'Iron Working and Welding',
    trade: {
      trade: 'Iron Working and Welding',
      category: 'Welding'
    }
  },
  {
    label: 'Labor Contracting',
    value: 'Labor Contracting',
    trade: { trade: 'Labor Contracting', category: 'Labor' }
  },
  {
    label: 'Laborers and Material Moving, Hand',
    value: 'Laborers and Material Moving, Hand',
    trade: {
      trade: 'Laborers and Material Moving, Hand',
      category: 'Material Moving Handling'
    }
  },
  {
    label: 'Ladders',
    value: 'Ladders',
    trade: { trade: 'Ladders', category: 'Structure' }
  },
  {
    label: 'Landscape Contractor',
    value: 'Landscape Contractor',
    trade: {
      trade: 'Landscape Contractor',
      category: 'Landscape'
    }
  },
  {
    label: 'Landscape Lighting',
    value: 'Landscape Lighting',
    trade: { trade: 'Landscape Lighting', category: 'Landscape' }
  },
  {
    label: 'Landscaping',
    value: 'Landscaping',
    trade: { trade: 'Landscaping', category: 'Landscape' }
  },
  {
    label: 'Landscaping Activities',
    value: 'Landscaping Activities',
    trade: {
      trade: 'Landscaping Activities',
      category: 'Landscape'
    }
  },
  {
    label: 'Landscaping Arborist',
    value: 'Landscaping Arborist',
    trade: {
      trade: 'Landscaping Arborist',
      category: 'Landscape'
    }
  },
  {
    label: 'Landscaping Asphalt (Asphalt Seal)',
    value: 'Landscaping Asphalt (Asphalt Seal)',
    trade: {
      trade: 'Landscaping Asphalt (Asphalt Seal)',
      category: 'Landscape'
    }
  },
  {
    label: 'Landscaping Contractor',
    value: 'Landscaping Contractor',
    trade: {
      trade: 'Landscaping Contractor',
      category: 'Landscape'
    }
  },
  {
    label: 'Landscaping Draining',
    value: 'Landscaping Draining',
    trade: {
      trade: 'Landscaping Draining',
      category: 'Landscape'
    }
  },
  {
    label: 'Landscaping Fencing',
    value: 'Landscaping Fencing',
    trade: { trade: 'Landscaping Fencing', category: 'Landscape' }
  },
  {
    label: 'Landscaping Mailbox',
    value: 'Landscaping Mailbox',
    trade: { trade: 'Landscaping Mailbox', category: 'Landscape' }
  },
  {
    label: 'Landscaping Pavement contractor (concrete flatwork)',
    value: 'Landscaping Pavement contractor (concrete flatwork)',
    trade: {
      trade: 'Landscaping Pavement contractor (concrete flatwork)',
      category: 'Landscape'
    }
  },
  {
    label: 'Lead Remediation',
    value: 'Lead Remediation',
    trade: { trade: 'Lead Remediation', category: 'Remediation' }
  },
  {
    label: 'Lifts',
    value: 'Lifts',
    trade: { trade: 'Lifts', category: 'Conveying' }
  },
  {
    label: 'Lighting',
    value: 'Lighting',
    trade: { trade: 'Lighting', category: 'Electric' }
  },
  {
    label: 'Lighting Control',
    value: 'Lighting Control',
    trade: { trade: 'Lighting Control', category: 'Electric' }
  },
  {
    label: 'Lighting Fixtures',
    value: 'Lighting Fixtures',
    trade: { trade: 'Lighting Fixtures', category: 'Electric' }
  },
  {
    label: 'Lighting Supplementary Components',
    value: 'Lighting Supplementary Components',
    trade: {
      trade: 'Lighting Supplementary Components',
      category: 'Electric'
    }
  },
  {
    label: 'Lightning Protection',
    value: 'Lightning Protection',
    trade: { trade: 'Lightning Protection', category: 'Electric' }
  },
  {
    label: 'Liquid and Gas Site Utilities',
    value: 'Liquid and Gas Site Utilities',
    trade: {
      trade: 'Liquid and Gas Site Utilities',
      category: 'Utilities'
    }
  },
  {
    label: 'Liquid and Gas Site Utilities Supplementary Components',
    value: 'Liquid and Gas Site Utilities Supplementary Components',
    trade: {
      trade: 'Liquid and Gas Site Utilities Supplementary Components',
      category: 'Utilities'
    }
  },
  {
    label: 'Loading Dock Equipment',
    value: 'Loading Dock Equipment',
    trade: {
      trade: 'Loading Dock Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Low-Slope Roofing',
    value: 'Low-Slope Roofing',
    trade: { trade: 'Low-Slope Roofing', category: 'Roof' }
  },
  {
    label: 'Maintenance Equipment',
    value: 'Maintenance Equipment',
    trade: {
      trade: 'Maintenance Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Manufactured Canopies',
    value: 'Manufactured Canopies',
    trade: {
      trade: 'Manufactured Canopies',
      category: 'Special Construction'
    }
  },
  {
    label: 'Manufactured/Fabricated Rooms',
    value: 'Manufactured/Fabricated Rooms',
    trade: {
      trade: 'Manufactured/Fabricated Rooms',
      category: 'Special Construction'
    }
  },
  {
    label: 'Manufacturer-Engineered Structures',
    value: 'Manufacturer-Engineered Structures',
    trade: {
      trade: 'Manufacturer-Engineered Structures',
      category: 'Special Construction'
    }
  },
  {
    label: 'Manufacturing Services and Supply',
    value: 'Manufacturing Services and Supply',
    trade: {
      trade: 'Manufacturing Services and Supply',
      category: 'Supplier'
    }
  },
  {
    label: 'Masonry',
    value: 'Masonry',
    trade: { trade: 'Masonry', category: 'Masonry' }
  },
  {
    label: 'Masonry Contracting',
    value: 'Masonry Contracting',
    trade: { trade: 'Masonry Contracting', category: 'Masonry' }
  },
  {
    label: 'Masonry Flooring',
    value: 'Masonry Flooring',
    trade: {
      trade: 'Masonry Flooring',
      category: 'Floors Stairs'
    }
  },
  {
    label: 'Masonry Restoration',
    value: 'Masonry Restoration',
    trade: {
      trade: 'Masonry Restoration',
      category: 'Restoration'
    }
  },
  {
    label: 'Masonry Stair Finish',
    value: 'Masonry Stair Finish',
    trade: {
      trade: 'Masonry Stair Finish',
      category: 'Floors Stairs'
    }
  },
  {
    label: 'Material Handling',
    value: 'Material Handling',
    trade: {
      trade: 'Material Handling',
      category: 'Material Moving Handling'
    }
  },
  {
    label: 'Material Moving Contracting',
    value: 'Material Moving Contracting',
    trade: {
      trade: 'Material Moving Contracting',
      category: 'Material Moving Handling'
    }
  },
  {
    label: 'Medium Voltage - less Than 1000 Volts (Home)',
    value: 'Medium Voltage - less Than 1000 Volts (Home)',
    trade: {
      trade: 'Medium Voltage - less Than 1000 Volts (Home)',
      category: 'Electric'
    }
  },
  {
    label: 'Mercantile and Service Equipment',
    value: 'Mercantile and Service Equipment',
    trade: {
      trade: 'Mercantile and Service Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Metal Siding',
    value: 'Metal Siding',
    trade: { trade: 'Metal Siding', category: 'Siding' }
  },
  {
    label: 'Metal Walkways',
    value: 'Metal Walkways',
    trade: { trade: 'Metal Walkways', category: 'Structure' }
  },
  {
    label: 'Meteorological Instrumentation',
    value: 'Meteorological Instrumentation',
    trade: {
      trade: 'Meteorological Instrumentation',
      category: 'Special Construction'
    }
  },
  {
    label: 'Methane Mitigation',
    value: 'Methane Mitigation',
    trade: {
      trade: 'Methane Mitigation',
      category: 'Substructure Mitigation'
    }
  },
  {
    label: 'Mezzanine Floor Construction',
    value: 'Mezzanine Floor Construction',
    trade: {
      trade: 'Mezzanine Floor Construction',
      category: 'Structure'
    }
  },
  {
    label: 'Mirrors',
    value: 'Mirrors',
    trade: { trade: 'Mirrors', category: 'Glass' }
  },
  {
    label: 'Miscellaneous Electrical Systems',
    value: 'Miscellaneous Electrical Systems',
    trade: {
      trade: 'Miscellaneous Electrical Systems',
      category: 'Electric'
    }
  },
  {
    label: 'Miscellaneous Electrical Systems Supplementary Components',
    value: 'Miscellaneous Electrical Systems Supplementary Components',
    trade: {
      trade: 'Miscellaneous Electrical Systems Supplementary Components',
      category: 'Electric'
    }
  },
  {
    label: 'Miscellaneous Site Construction',
    value: 'Miscellaneous Site Construction',
    trade: {
      trade: 'Miscellaneous Site Construction',
      category: 'Special Construction'
    }
  },
  {
    label: 'Modular Mezzanines',
    value: 'Modular Mezzanines',
    trade: {
      trade: 'Modular Mezzanines',
      category: 'Special Construction'
    }
  },
  {
    label: 'Mold Remediation',
    value: 'Mold Remediation',
    trade: { trade: 'Mold Remediation', category: 'Remediation' }
  },
  {
    label: 'Movable Art',
    value: 'Movable Art',
    trade: { trade: 'Movable Art', category: 'Furnishings' }
  },
  {
    label: 'Movable Furnishings',
    value: 'Movable Furnishings',
    trade: {
      trade: 'Movable Furnishings',
      category: 'Furnishings'
    }
  },
  {
    label: 'Movable Multiple Seating',
    value: 'Movable Multiple Seating',
    trade: {
      trade: 'Movable Multiple Seating',
      category: 'Furnishings'
    }
  },
  {
    label: 'Move In Contracting',
    value: 'Move In Contracting',
    trade: {
      trade: 'Move In Contracting',
      category: 'Special Interior'
    }
  },
  {
    label: 'Movers, Appliance Movers and Funiture Movers',
    value: 'Movers, Appliance Movers and Funiture Movers',
    trade: {
      trade: 'Movers, Appliance Movers and Funiture Movers',
      category: 'Special Interior'
    }
  },
  {
    label: 'Moving Ramps',
    value: 'Moving Ramps',
    trade: { trade: 'Moving Ramps', category: 'Conveying' }
  },
  {
    label: 'Moving Walks',
    value: 'Moving Walks',
    trade: { trade: 'Moving Walks', category: 'Conveying' }
  },
  {
    label: 'Mud Only',
    value: 'Mud Only',
    trade: { trade: 'Mud Only', category: 'Ceiling Wall' }
  },
  {
    label: 'Mud Slab',
    value: 'Mud Slab',
    trade: {
      trade: 'Mud Slab',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Mud and Tape',
    value: 'Mud and Tape',
    trade: { trade: 'Mud and Tape', category: 'Ceiling Wall' }
  },
  {
    label: 'Musical Equipment',
    value: 'Musical Equipment',
    trade: { trade: 'Musical Equipment', category: 'Equipment' }
  },
  {
    label: 'Off-Gassing Mitigation',
    value: 'Off-Gassing Mitigation',
    trade: {
      trade: 'Off-Gassing Mitigation',
      category: 'Substructure Mitigation'
    }
  },
  {
    label: 'Office Equipment',
    value: 'Office Equipment',
    trade: { trade: 'Office Equipment', category: 'Equipment' }
  },
  {
    label: 'Operable Access Systems',
    value: 'Operable Access Systems',
    trade: {
      trade: 'Operable Access Systems',
      category: 'Scaffolding'
    }
  },
  {
    label: 'Other Equipment',
    value: 'Other Equipment',
    trade: { trade: 'Other Equipment', category: 'Equipment' }
  },
  {
    label: 'Other Fixed Furnishings',
    value: 'Other Fixed Furnishings',
    trade: {
      trade: 'Other Fixed Furnishings',
      category: 'Furnishings'
    }
  },
  {
    label: 'Other Interior Specialties',
    value: 'Other Interior Specialties',
    trade: {
      trade: 'Other Interior Specialties',
      category: 'Special Interior'
    }
  },
  {
    label: 'Other Movable Furnishings',
    value: 'Other Movable Furnishings',
    trade: {
      trade: 'Other Movable Furnishings',
      category: 'Furnishings'
    }
  },
  {
    label: 'Other Painting',
    value: 'Other Painting',
    trade: { trade: 'Other Painting', category: 'Paint' }
  },
  {
    label: 'Outside Air',
    value: 'Outside Air',
    trade: { trade: 'Outside Air', category: 'HVAC' }
  },
  {
    label: 'Overhead Exterior Enclosures',
    value: 'Overhead Exterior Enclosures',
    trade: {
      trade: 'Overhead Exterior Enclosures',
      category: 'Structure'
    }
  },
  {
    label: 'Packaged Generator Assemblies',
    value: 'Packaged Generator Assemblies',
    trade: {
      trade: 'Packaged Generator Assemblies',
      category: 'Electric'
    }
  },
  {
    label: 'Parapets',
    value: 'Parapets',
    trade: { trade: 'Parapets', category: 'Structure' }
  },
  {
    label: 'Parking Lot Appurtenances',
    value: 'Parking Lot Appurtenances',
    trade: {
      trade: 'Parking Lot Appurtenances',
      category: 'Roadway'
    }
  },
  {
    label: 'Parking Lot Curbs and Gutters',
    value: 'Parking Lot Curbs and Gutters',
    trade: {
      trade: 'Parking Lot Curbs and Gutters',
      category: 'Roadway'
    }
  },
  {
    label: 'Parking Lot Lighting',
    value: 'Parking Lot Lighting',
    trade: { trade: 'Parking Lot Lighting', category: 'Roadway' }
  },
  {
    label: 'Parking Lot Pavement',
    value: 'Parking Lot Pavement',
    trade: { trade: 'Parking Lot Pavement', category: 'Roadway' }
  },
  {
    label: 'Parking Lots',
    value: 'Parking Lots',
    trade: { trade: 'Parking Lots', category: 'Roadway' }
  },
  {
    label: 'Parking Restoration',
    value: 'Parking Restoration',
    trade: {
      trade: 'Parking Restoration',
      category: 'Restoration'
    }
  },
  {
    label: 'Passenger Loading Bridges',
    value: 'Passenger Loading Bridges',
    trade: {
      trade: 'Passenger Loading Bridges',
      category: 'Conveying'
    }
  },
  {
    label: 'Pedestrian Pavement',
    value: 'Pedestrian Pavement',
    trade: { trade: 'Pedestrian Pavement', category: 'Roadway' }
  },
  {
    label: 'Pedestrian Pavement Appurtenances',
    value: 'Pedestrian Pavement Appurtenances',
    trade: {
      trade: 'Pedestrian Pavement Appurtenances',
      category: 'Roadway'
    }
  },
  {
    label: 'Pedestrian Pavement Curbs and Gutters',
    value: 'Pedestrian Pavement Curbs and Gutters',
    trade: {
      trade: 'Pedestrian Pavement Curbs and Gutters',
      category: 'Roadway'
    }
  },
  {
    label: 'Pedestrian Plazas and Walkways',
    value: 'Pedestrian Plazas and Walkways',
    trade: {
      trade: 'Pedestrian Plazas and Walkways',
      category: 'Roadway'
    }
  },
  {
    label: 'Pedestrian Tunnels',
    value: 'Pedestrian Tunnels',
    trade: {
      trade: 'Pedestrian Tunnels',
      category: 'Special Construction'
    }
  },
  {
    label: 'People Movers',
    value: 'People Movers',
    trade: { trade: 'People Movers', category: 'Conveying' }
  },
  {
    label: 'Perimeter Insulation',
    value: 'Perimeter Insulation',
    trade: {
      trade: 'Perimeter Insulation',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Pest Control',
    value: 'Pest Control',
    trade: { trade: 'Pest Control', category: 'Cleaning' }
  },
  {
    label: 'Photographic Processing Equipment',
    value: 'Photographic Processing Equipment',
    trade: {
      trade: 'Photographic Processing Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Photovoltaic Collectors',
    value: 'Photovoltaic Collectors',
    trade: {
      trade: 'Photovoltaic Collectors',
      category: 'Electric'
    }
  },
  {
    label: 'Physical Decontamination',
    value: 'Physical Decontamination',
    trade: {
      trade: 'Physical Decontamination',
      category: 'Remediation'
    }
  },
  {
    label: 'Pile Caps',
    value: 'Pile Caps',
    trade: {
      trade: 'Pile Caps',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Pits and Bases',
    value: 'Pits and Bases',
    trade: {
      trade: 'Pits and Bases',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Planting Accessories',
    value: 'Planting Accessories',
    trade: {
      trade: 'Planting Accessories',
      category: 'Landscape'
    }
  },
  {
    label: 'Planting Irrigation',
    value: 'Planting Irrigation',
    trade: { trade: 'Planting Irrigation', category: 'Landscape' }
  },
  {
    label: 'Plants',
    value: 'Plants',
    trade: { trade: 'Plants', category: 'Landscape' }
  },
  {
    label: 'Plaster and Gypsum Board Finish',
    value: 'Plaster and Gypsum Board Finish',
    trade: {
      trade: 'Plaster and Gypsum Board Finish',
      category: 'Ceiling Wall'
    }
  },
  {
    label: 'Platform/Stage Floors',
    value: 'Platform/Stage Floors',
    trade: {
      trade: 'Platform/Stage Floors',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Playfield Areas',
    value: 'Playfield Areas',
    trade: { trade: 'Playfield Areas', category: 'Recreational' }
  },
  {
    label: 'Plaza and Walkway Lighting',
    value: 'Plaza and Walkway Lighting',
    trade: {
      trade: 'Plaza and Walkway Lighting',
      category: 'Roadway'
    }
  },
  {
    label: 'Plumbing',
    value: 'Plumbing',
    trade: { trade: 'Plumbing', category: 'Plumbing' }
  },
  {
    label: 'Plumbing - Other',
    value: 'Plumbing - Other',
    trade: { trade: 'Plumbing - Other', category: 'Plumbing' }
  },
  {
    label: 'Plumbing Fixtures',
    value: 'Plumbing Fixtures',
    trade: { trade: 'Plumbing Fixtures', category: 'Plumbing' }
  },
  {
    label: 'Pneumatic Tube Systems',
    value: 'Pneumatic Tube Systems',
    trade: {
      trade: 'Pneumatic Tube Systems',
      category: 'Material Moving Handling'
    }
  },
  {
    label: 'Polychlorinate Biphenyl Remediation',
    value: 'Polychlorinate Biphenyl Remediation',
    trade: {
      trade: 'Polychlorinate Biphenyl Remediation',
      category: 'Remediation'
    }
  },
  {
    label: 'Pools',
    value: 'Pools',
    trade: { trade: 'Pools', category: 'Recreational' }
  },
  {
    label: 'Postal, Packaging, and Shipping Equipment',
    value: 'Postal, Packaging, and Shipping Equipment',
    trade: {
      trade: 'Postal, Packaging, and Shipping Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Power Distribution',
    value: 'Power Distribution',
    trade: { trade: 'Power Distribution', category: 'Electric' }
  },
  {
    label: 'Power Filtering and Conditioning',
    value: 'Power Filtering and Conditioning',
    trade: {
      trade: 'Power Filtering and Conditioning',
      category: 'Electric'
    }
  },
  {
    label: 'Powered Scaffolding',
    value: 'Powered Scaffolding',
    trade: {
      trade: 'Powered Scaffolding',
      category: 'Scaffolding'
    }
  },
  {
    label: 'Process Manufacturing',
    value: 'Process Manufacturing',
    trade: {
      trade: 'Process Manufacturing',
      category: 'Supplier'
    }
  },
  {
    label: 'Process Support Plumbing System Supplementary Components',
    value: 'Process Support Plumbing System Supplementary Components',
    trade: {
      trade: 'Process Support Plumbing System Supplementary Components',
      category: 'Plumbing'
    }
  },
  {
    label: 'Process Support Plumbing Systems',
    value: 'Process Support Plumbing Systems',
    trade: {
      trade: 'Process Support Plumbing Systems',
      category: 'Plumbing'
    }
  },
  {
    label: 'Processed Water Systems',
    value: 'Processed Water Systems',
    trade: {
      trade: 'Processed Water Systems',
      category: 'Plumbing'
    }
  },
  {
    label: 'Radiation Detection and Alarm',
    value: 'Radiation Detection and Alarm',
    trade: {
      trade: 'Radiation Detection and Alarm',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Radiation Protection',
    value: 'Radiation Protection',
    trade: {
      trade: 'Radiation Protection',
      category: 'Special Construction'
    }
  },
  {
    label: 'Radon Mitigation',
    value: 'Radon Mitigation',
    trade: {
      trade: 'Radon Mitigation',
      category: 'Substructure Mitigation'
    }
  },
  {
    label: 'Raft Foundations',
    value: 'Raft Foundations',
    trade: {
      trade: 'Raft Foundations',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Rainwater Management',
    value: 'Rainwater Management',
    trade: { trade: 'Rainwater Management', category: 'Roof' }
  },
  {
    label: 'Raised Floor Construction',
    value: 'Raised Floor Construction',
    trade: {
      trade: 'Raised Floor Construction',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Rammed Earth Construction',
    value: 'Rammed Earth Construction',
    trade: {
      trade: 'Rammed Earth Construction',
      category: 'Special Construction'
    }
  },
  {
    label: 'Ramps',
    value: 'Ramps',
    trade: { trade: 'Ramps', category: 'Structure' }
  },
  {
    label: 'Recreational Areas',
    value: 'Recreational Areas',
    trade: {
      trade: 'Recreational Areas',
      category: 'Recreational'
    }
  },
  {
    label: 'Recreational Equipment',
    value: 'Recreational Equipment',
    trade: {
      trade: 'Recreational Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Refrigerated Display Equipment',
    value: 'Refrigerated Display Equipment',
    trade: {
      trade: 'Refrigerated Display Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Refrigeration',
    value: 'Refrigeration',
    trade: { trade: 'Refrigeration', category: 'HVAC' }
  },
  {
    label: 'Refrigeration Contracting',
    value: 'Refrigeration Contracting',
    trade: {
      trade: 'Refrigeration Contracting',
      category: 'HVAC'
    }
  },
  {
    label: 'Refrigeration Detection and Alarm',
    value: 'Refrigeration Detection and Alarm',
    trade: {
      trade: 'Refrigeration Detection and Alarm',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Reinforcing Iron and Rebar Fabrication',
    value: 'Reinforcing Iron and Rebar Fabrication',
    trade: {
      trade: 'Reinforcing Iron and Rebar Fabrication',
      category: 'Welding'
    }
  },
  {
    label: 'Religious Equipment',
    value: 'Religious Equipment',
    trade: { trade: 'Religious Equipment', category: 'Equipment' }
  },
  {
    label: 'Remediation',
    value: 'Remediation',
    trade: { trade: 'Remediation', category: 'Site Prep' }
  },
  {
    label: 'Remediation Soil Stabilization',
    value: 'Remediation Soil Stabilization',
    trade: {
      trade: 'Remediation Soil Stabilization',
      category: 'Remediation'
    }
  },
  {
    label: 'Residential Appliances',
    value: 'Residential Appliances',
    trade: {
      trade: 'Residential Appliances',
      category: 'Furnishings'
    }
  },
  {
    label: 'Residential Ceiling Fans',
    value: 'Residential Ceiling Fans',
    trade: {
      trade: 'Residential Ceiling Fans',
      category: 'Electric'
    }
  },
  {
    label: 'Residential Equipment',
    value: 'Residential Equipment',
    trade: {
      trade: 'Residential Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Resilient Flooring',
    value: 'Resilient Flooring',
    trade: {
      trade: 'Resilient Flooring',
      category: 'Floors Stairs'
    }
  },
  {
    label: 'Resilient Stair Finish',
    value: 'Resilient Stair Finish',
    trade: {
      trade: 'Resilient Stair Finish',
      category: 'Floors Stairs'
    }
  },
  {
    label: 'Restoration Services',
    value: 'Restoration Services',
    trade: {
      trade: 'Restoration Services',
      category: 'Restoration'
    }
  },
  {
    label: 'Restoration Services - General',
    value: 'Restoration Services - General',
    trade: {
      trade: 'Restoration Services - General',
      category: 'Restoration'
    }
  },
  {
    label: 'Retaining Walls',
    value: 'Retaining Walls',
    trade: { trade: 'Retaining Walls', category: 'Landscape' }
  },
  {
    label: 'Retractable Stairs',
    value: 'Retractable Stairs',
    trade: { trade: 'Retractable Stairs', category: 'Equipment' }
  },
  {
    label: 'Return Air',
    value: 'Return Air',
    trade: { trade: 'Return Air', category: 'HVAC' }
  },
  {
    label: 'Riprap',
    value: 'Riprap',
    trade: { trade: 'Riprap', category: 'Earthwork' }
  },
  {
    label: 'Roadway Appurtenances',
    value: 'Roadway Appurtenances',
    trade: { trade: 'Roadway Appurtenances', category: 'Roadway' }
  },
  {
    label: 'Roadway Curbs and Gutters',
    value: 'Roadway Curbs and Gutters',
    trade: {
      trade: 'Roadway Curbs and Gutters',
      category: 'Roadway'
    }
  },
  {
    label: 'Roadway Lighting',
    value: 'Roadway Lighting',
    trade: { trade: 'Roadway Lighting', category: 'Roadway' }
  },
  {
    label: 'Roadway Pavement',
    value: 'Roadway Pavement',
    trade: { trade: 'Roadway Pavement', category: 'Roadway' }
  },
  {
    label: 'Roadways',
    value: 'Roadways',
    trade: { trade: 'Roadways', category: 'Roadway' }
  },
  {
    label: 'Rock Stabilization',
    value: 'Rock Stabilization',
    trade: { trade: 'Rock Stabilization', category: 'Earthwork' }
  },
  {
    label: 'Roof Accessories',
    value: 'Roof Accessories',
    trade: { trade: 'Roof Accessories', category: 'Roof' }
  },
  {
    label: 'Roof Appurtenances',
    value: 'Roof Appurtenances',
    trade: { trade: 'Roof Appurtenances', category: 'Roof' }
  },
  {
    label: 'Roof Construction',
    value: 'Roof Construction',
    trade: { trade: 'Roof Construction', category: 'Structure' }
  },
  {
    label: 'Roof Construction Supplementary Components',
    value: 'Roof Construction Supplementary Components',
    trade: {
      trade: 'Roof Construction Supplementary Components',
      category: 'Structure'
    }
  },
  {
    label: 'Roof Decks, Slabs, and Sheathing',
    value: 'Roof Decks, Slabs, and Sheathing',
    trade: {
      trade: 'Roof Decks, Slabs, and Sheathing',
      category: 'Structure'
    }
  },
  {
    label: 'Roof Specialties',
    value: 'Roof Specialties',
    trade: { trade: 'Roof Specialties', category: 'Roof' }
  },
  {
    label: 'Roof Structural Frame',
    value: 'Roof Structural Frame',
    trade: {
      trade: 'Roof Structural Frame',
      category: 'Structure'
    }
  },
  {
    label: 'Roof Windows and Skylights',
    value: 'Roof Windows and Skylights',
    trade: {
      trade: 'Roof Windows and Skylights',
      category: 'Roof'
    }
  },
  {
    label: 'Roofing',
    value: 'Roofing',
    trade: { trade: 'Roofing', category: 'Roof' }
  },
  {
    label: 'Roofing Supplementary Components',
    value: 'Roofing Supplementary Components',
    trade: {
      trade: 'Roofing Supplementary Components',
      category: 'Roof'
    }
  },
  {
    label: 'Rope Climbers',
    value: 'Rope Climbers',
    trade: { trade: 'Rope Climbers', category: 'Scaffolding' }
  },
  {
    label: 'Rough Carpentry Contracting',
    value: 'Rough Carpentry Contracting',
    trade: {
      trade: 'Rough Carpentry Contracting',
      category: 'Structure'
    }
  },
  {
    label: 'Rough in Plumbing',
    value: 'Rough in Plumbing',
    trade: { trade: 'Rough in Plumbing', category: 'Plumbing' }
  },
  {
    label: 'Safety Netting',
    value: 'Safety Netting',
    trade: { trade: 'Safety Netting', category: 'Recreational' }
  },
  {
    label: 'Safety Specialties',
    value: 'Safety Specialties',
    trade: {
      trade: 'Safety Specialties',
      category: 'Special Interior'
    }
  },
  {
    label: 'Sanitary Drainage',
    value: 'Sanitary Drainage',
    trade: { trade: 'Sanitary Drainage', category: 'Plumbing' }
  },
  {
    label: 'Sanitary Drainage Supplementary Components',
    value: 'Sanitary Drainage Supplementary Components',
    trade: {
      trade: 'Sanitary Drainage Supplementary Components',
      category: 'Plumbing'
    }
  },
  {
    label: 'Sanitary Sewerage Equipment',
    value: 'Sanitary Sewerage Equipment',
    trade: {
      trade: 'Sanitary Sewerage Equipment',
      category: 'Plumbing'
    }
  },
  {
    label: 'Sanitary Sewerage Lagoons',
    value: 'Sanitary Sewerage Lagoons',
    trade: {
      trade: 'Sanitary Sewerage Lagoons',
      category: 'Utilities'
    }
  },
  {
    label: 'Sanitary Sewerage Piping',
    value: 'Sanitary Sewerage Piping',
    trade: {
      trade: 'Sanitary Sewerage Piping',
      category: 'Utilities'
    }
  },
  {
    label: 'Sanitary Sewerage Piping',
    value: 'Sanitary Sewerage Piping',
    trade: {
      trade: 'Sanitary Sewerage Piping',
      category: 'Plumbing'
    }
  },
  {
    label: 'Sanitary Sewerage Structures',
    value: 'Sanitary Sewerage Structures',
    trade: {
      trade: 'Sanitary Sewerage Structures',
      category: 'Utilities'
    }
  },
  {
    label: 'Sanitary Sewerage Utilities',
    value: 'Sanitary Sewerage Utilities',
    trade: {
      trade: 'Sanitary Sewerage Utilities',
      category: 'Utilities'
    }
  },
  {
    label: 'Sanitary Sewerage Utility Connection',
    value: 'Sanitary Sewerage Utility Connection',
    trade: {
      trade: 'Sanitary Sewerage Utility Connection',
      category: 'Utilities'
    }
  },
  {
    label: 'Security Equipment',
    value: 'Security Equipment',
    trade: { trade: 'Security Equipment', category: 'Equipment' }
  },
  {
    label: 'Seismic Control',
    value: 'Seismic Control',
    trade: {
      trade: 'Seismic Control',
      category: 'Special Construction'
    }
  },
  {
    label: 'Seismic Control',
    value: 'Seismic Control',
    trade: { trade: 'Seismic Control', category: 'Site Prep' }
  },
  {
    label: 'Seismic Instrumentation',
    value: 'Seismic Instrumentation',
    trade: {
      trade: 'Seismic Instrumentation',
      category: 'Special Construction'
    }
  },
  {
    label: 'Selective Bridge Demolition',
    value: 'Selective Bridge Demolition',
    trade: {
      trade: 'Selective Bridge Demolition',
      category: 'Demolition'
    }
  },
  {
    label: 'Selective Building Demolition',
    value: 'Selective Building Demolition',
    trade: {
      trade: 'Selective Building Demolition',
      category: 'Demolition'
    }
  },
  {
    label: 'Selective Demolition',
    value: 'Selective Demolition',
    trade: {
      trade: 'Selective Demolition',
      category: 'Demolition'
    }
  },
  {
    label: 'Selective Historic Demolition',
    value: 'Selective Historic Demolition',
    trade: {
      trade: 'Selective Historic Demolition',
      category: 'Demolition'
    }
  },
  {
    label: 'Selective Interior Demolition',
    value: 'Selective Interior Demolition',
    trade: {
      trade: 'Selective Interior Demolition',
      category: 'Demolition'
    }
  },
  {
    label: 'Selective Site Demolition',
    value: 'Selective Site Demolition',
    trade: {
      trade: 'Selective Site Demolition',
      category: 'Site Prep'
    }
  },
  {
    label: 'Septic Tank Services and Sewer Pipe Cleaning',
    value: 'Septic Tank Services and Sewer Pipe Cleaning',
    trade: {
      trade: 'Septic Tank Services and Sewer Pipe Cleaning',
      category: 'Garbage'
    }
  },
  {
    label: 'Service Tunnels',
    value: 'Service Tunnels',
    trade: {
      trade: 'Service Tunnels',
      category: 'Special Construction'
    }
  },
  {
    label: 'Service Walls',
    value: 'Service Walls',
    trade: {
      trade: 'Service Walls',
      category: 'Special Interior'
    }
  },
  {
    label: 'Sewer Utility',
    value: 'Sewer Utility',
    trade: { trade: 'Sewer Utility', category: 'Utilities' }
  },
  {
    label: 'Sewerage / Ecological Toilets / Portalets',
    value: 'Sewerage / Ecological Toilets / Portalets',
    trade: {
      trade: 'Sewerage / Ecological Toilets / Portalets',
      category: 'Site Prep'
    }
  },
  {
    label: 'Sheet Metal Working (tin knocker)',
    value: 'Sheet Metal Working (tin knocker)',
    trade: {
      trade: 'Sheet Metal Working (tin knocker)',
      category: 'Sheet Metal'
    }
  },
  {
    label: 'Sheet Metal Working Contracting',
    value: 'Sheet Metal Working Contracting',
    trade: {
      trade: 'Sheet Metal Working Contracting',
      category: 'Sheet Metal'
    }
  },
  {
    label: 'Shell',
    value: 'Shell',
    trade: { trade: 'Shell', category: 'Structure' }
  },
  {
    label: 'Shoring',
    value: 'Shoring',
    trade: { trade: 'Shoring', category: 'Site Prep' }
  },
  {
    label: 'Sinkhole Remediation',
    value: 'Sinkhole Remediation',
    trade: {
      trade: 'Sinkhole Remediation',
      category: 'Remediation'
    }
  },
  {
    label: 'Site Aviation Fuel Distribution',
    value: 'Site Aviation Fuel Distribution',
    trade: {
      trade: 'Site Aviation Fuel Distribution',
      category: 'Utilities'
    }
  },
  {
    label: 'Site Bridges',
    value: 'Site Bridges',
    trade: { trade: 'Site Bridges', category: 'Landscape' }
  },
  {
    label: 'Site Clearing',
    value: 'Site Clearing',
    trade: { trade: 'Site Clearing', category: 'Site Prep' }
  },
  {
    label: 'Site Communications',
    value: 'Site Communications',
    trade: {
      trade: 'Site Communications',
      category: 'Communications'
    }
  },
  {
    label: 'Site Communications Distribution',
    value: 'Site Communications Distribution',
    trade: {
      trade: 'Site Communications Distribution',
      category: 'Communications'
    }
  },
  {
    label: 'Site Communications Structures',
    value: 'Site Communications Structures',
    trade: {
      trade: 'Site Communications Structures',
      category: 'Communications'
    }
  },
  {
    label: 'Site Communications Systems',
    value: 'Site Communications Systems',
    trade: {
      trade: 'Site Communications Systems',
      category: 'Communications'
    }
  },
  {
    label: 'Site Containment',
    value: 'Site Containment',
    trade: { trade: 'Site Containment', category: 'Remediation' }
  },
  {
    label: 'Site Development',
    value: 'Site Development',
    trade: { trade: 'Site Development', category: 'Landscape' }
  },
  {
    label: 'Site Diesel Fuel Distribution',
    value: 'Site Diesel Fuel Distribution',
    trade: {
      trade: 'Site Diesel Fuel Distribution',
      category: 'Utilities'
    }
  },
  {
    label: 'Site Domestic Water Distribution',
    value: 'Site Domestic Water Distribution',
    trade: {
      trade: 'Site Domestic Water Distribution',
      category: 'Utilities'
    }
  },
  {
    label: 'Site Earthwork',
    value: 'Site Earthwork',
    trade: { trade: 'Site Earthwork', category: 'Earthwork' }
  },
  {
    label: 'Site Electric Distribution Systems',
    value: 'Site Electric Distribution Systems',
    trade: {
      trade: 'Site Electric Distribution Systems',
      category: 'Electric'
    }
  },
  {
    label: 'Site Element Relocations',
    value: 'Site Element Relocations',
    trade: {
      trade: 'Site Element Relocations',
      category: 'Site Prep'
    }
  },
  {
    label: 'Site Elements Demolition',
    value: 'Site Elements Demolition',
    trade: {
      trade: 'Site Elements Demolition',
      category: 'Site Prep'
    }
  },
  {
    label: 'Site Energy Distribution',
    value: 'Site Energy Distribution',
    trade: {
      trade: 'Site Energy Distribution',
      category: 'Utilities'
    }
  },
  {
    label: 'Site Equipment',
    value: 'Site Equipment',
    trade: { trade: 'Site Equipment', category: 'Landscape' }
  },
  {
    label: 'Site Fire Protection Water Distribution',
    value: 'Site Fire Protection Water Distribution',
    trade: {
      trade: 'Site Fire Protection Water Distribution',
      category: 'Utilities'
    }
  },
  {
    label: 'Site Fuel Distribution',
    value: 'Site Fuel Distribution',
    trade: {
      trade: 'Site Fuel Distribution',
      category: 'Utilities'
    }
  },
  {
    label: 'Site Fuel-Oil Distribution',
    value: 'Site Fuel-Oil Distribution',
    trade: {
      trade: 'Site Fuel-Oil Distribution',
      category: 'Utilities'
    }
  },
  {
    label: 'Site Furnishings',
    value: 'Site Furnishings',
    trade: { trade: 'Site Furnishings', category: 'Landscape' }
  },
  {
    label: 'Site Gas Distribution',
    value: 'Site Gas Distribution',
    trade: {
      trade: 'Site Gas Distribution',
      category: 'Utilities'
    }
  },
  {
    label: 'Site Gasoline Distribution',
    value: 'Site Gasoline Distribution',
    trade: {
      trade: 'Site Gasoline Distribution',
      category: 'Utilities'
    }
  },
  {
    label: 'Site Grounding',
    value: 'Site Grounding',
    trade: { trade: 'Site Grounding', category: 'Electric' }
  },
  {
    label: 'Site Hydronic Cooling Distribution',
    value: 'Site Hydronic Cooling Distribution',
    trade: {
      trade: 'Site Hydronic Cooling Distribution',
      category: 'Utilities'
    }
  },
  {
    label: 'Site Hydronic Heating Distribution',
    value: 'Site Hydronic Heating Distribution',
    trade: {
      trade: 'Site Hydronic Heating Distribution',
      category: 'Utilities'
    }
  },
  {
    label: 'Site Improvements',
    value: 'Site Improvements',
    trade: { trade: 'Site Improvements', category: 'Site Prep' }
  },
  {
    label: 'Site Irrigation Water Distribution',
    value: 'Site Irrigation Water Distribution',
    trade: {
      trade: 'Site Irrigation Water Distribution',
      category: 'Utilities'
    }
  },
  {
    label: 'Site Lighting',
    value: 'Site Lighting',
    trade: { trade: 'Site Lighting', category: 'Electric' }
  },
  {
    label: 'Site Preparation',
    value: 'Site Preparation',
    trade: { trade: 'Site Preparation', category: 'Site Prep' }
  },
  {
    label: 'Site Preparation - Other',
    value: 'Site Preparation - Other',
    trade: {
      trade: 'Site Preparation - Other',
      category: 'Site Prep'
    }
  },
  {
    label: 'Site Preparation Contracting',
    value: 'Site Preparation Contracting',
    trade: {
      trade: 'Site Preparation Contracting',
      category: 'Site Prep'
    }
  },
  {
    label: 'Site Remediation',
    value: 'Site Remediation',
    trade: { trade: 'Site Remediation', category: 'Remediation' }
  },
  {
    label: 'Site Screening Devices',
    value: 'Site Screening Devices',
    trade: {
      trade: 'Site Screening Devices',
      category: 'Landscape'
    }
  },
  {
    label: 'Site Soil Treatment',
    value: 'Site Soil Treatment',
    trade: { trade: 'Site Soil Treatment', category: 'Earthwork' }
  },
  {
    label: 'Site Specialties',
    value: 'Site Specialties',
    trade: { trade: 'Site Specialties', category: 'Landscape' }
  },
  {
    label: 'Site Steam Energy Distribution',
    value: 'Site Steam Energy Distribution',
    trade: {
      trade: 'Site Steam Energy Distribution',
      category: 'Utilities'
    }
  },
  {
    label: 'Site Storm Water Drains',
    value: 'Site Storm Water Drains',
    trade: {
      trade: 'Site Storm Water Drains',
      category: 'Utilities'
    }
  },
  {
    label: 'Site Subdrainage',
    value: 'Site Subdrainage',
    trade: { trade: 'Site Subdrainage', category: 'Utilities' }
  },
  {
    label: 'Sitework',
    value: 'Sitework',
    trade: { trade: 'Sitework', category: 'Site Prep' }
  },
  {
    label: 'Sky Lights',
    value: 'Sky Lights',
    trade: { trade: 'Sky Lights', category: 'Glass' }
  },
  {
    label: 'Slab Trenches',
    value: 'Slab Trenches',
    trade: {
      trade: 'Slab Trenches',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Slab-On-Grade Supplementary Components',
    value: 'Slab-On-Grade Supplementary Components',
    trade: {
      trade: 'Slab-On-Grade Supplementary Components',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Slabs-On-Grade',
    value: 'Slabs-On-Grade',
    trade: {
      trade: 'Slabs-On-Grade',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Slope Protection',
    value: 'Slope Protection',
    trade: { trade: 'Slope Protection', category: 'Earthwork' }
  },
  {
    label: 'Slurry Walls',
    value: 'Slurry Walls',
    trade: {
      trade: 'Slurry Walls',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Snow Melting',
    value: 'Snow Melting',
    trade: { trade: 'Snow Melting', category: 'HVAC' }
  },
  {
    label: 'Soil Reinforcement',
    value: 'Soil Reinforcement',
    trade: { trade: 'Soil Reinforcement', category: 'Earthwork' }
  },
  {
    label: 'Soil Stabilization',
    value: 'Soil Stabilization',
    trade: { trade: 'Soil Stabilization', category: 'Earthwork' }
  },
  {
    label: 'Soil Treatment',
    value: 'Soil Treatment',
    trade: {
      trade: 'Soil Treatment',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Solar Photovoltaic Service',
    value: 'Solar Photovoltaic Service',
    trade: {
      trade: 'Solar Photovoltaic Service',
      category: 'Utilities'
    }
  },
  {
    label: 'Solid Waste Collection',
    value: 'Solid Waste Collection',
    trade: {
      trade: 'Solid Waste Collection',
      category: 'Garbage'
    }
  },
  {
    label: 'Solid Waste Handling Equipment',
    value: 'Solid Waste Handling Equipment',
    trade: {
      trade: 'Solid Waste Handling Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Sound and Vibration Control',
    value: 'Sound and Vibration Control',
    trade: {
      trade: 'Sound and Vibration Control',
      category: 'Special Construction'
    }
  },
  {
    label: 'Space Frames',
    value: 'Space Frames',
    trade: {
      trade: 'Space Frames',
      category: 'Special Construction'
    }
  },
  {
    label: 'Special Construction',
    value: 'Special Construction',
    trade: {
      trade: 'Special Construction',
      category: 'Special Construction'
    }
  },
  {
    label: 'Special Construction and Demolition',
    value: 'Special Construction and Demolition',
    trade: {
      trade: 'Special Construction and Demolition',
      category: 'Special Construction'
    }
  },
  {
    label: 'Special Facility Components',
    value: 'Special Facility Components',
    trade: {
      trade: 'Special Facility Components',
      category: 'Recreational'
    }
  },
  {
    label: 'Special Foundation Walls',
    value: 'Special Foundation Walls',
    trade: {
      trade: 'Special Foundation Walls',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Special Foundations',
    value: 'Special Foundations',
    trade: {
      trade: 'Special Foundations',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Special Function Construction',
    value: 'Special Function Construction',
    trade: {
      trade: 'Special Function Construction',
      category: 'Special Construction'
    }
  },
  {
    label: 'Special Function Suspended Ceilings',
    value: 'Special Function Suspended Ceilings',
    trade: {
      trade: 'Special Function Suspended Ceilings',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Special Instrumentation',
    value: 'Special Instrumentation',
    trade: {
      trade: 'Special Instrumentation',
      category: 'Special Construction'
    }
  },
  {
    label: 'Special Purpose HVAC Systems',
    value: 'Special Purpose HVAC Systems',
    trade: {
      trade: 'Special Purpose HVAC Systems',
      category: 'HVAC'
    }
  },
  {
    label: 'Special Structures',
    value: 'Special Structures',
    trade: {
      trade: 'Special Structures',
      category: 'Special Construction'
    }
  },
  {
    label: 'Special Wall Surfacing',
    value: 'Special Wall Surfacing',
    trade: {
      trade: 'Special Wall Surfacing',
      category: 'Ceiling Wall'
    }
  },
  {
    label: 'Specialty Flooring',
    value: 'Specialty Flooring',
    trade: {
      trade: 'Specialty Flooring',
      category: 'Floors Stairs'
    }
  },
  {
    label: 'Specialty Suspended Ceilings',
    value: 'Specialty Suspended Ceilings',
    trade: {
      trade: 'Specialty Suspended Ceilings',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Stair Construction',
    value: 'Stair Construction',
    trade: { trade: 'Stair Construction', category: 'Structure' }
  },
  {
    label: 'Stair Finishes',
    value: 'Stair Finishes',
    trade: { trade: 'Stair Finishes', category: 'Floors Stairs' }
  },
  {
    label: 'Stair Railings',
    value: 'Stair Railings',
    trade: { trade: 'Stair Railings', category: 'Structure' }
  },
  {
    label: 'Stair Soffits',
    value: 'Stair Soffits',
    trade: { trade: 'Stair Soffits', category: 'Structure' }
  },
  {
    label: 'Stairs',
    value: 'Stairs',
    trade: { trade: 'Stairs', category: 'Structure' }
  },
  {
    label: 'Standard Foundation Supplementary Components',
    value: 'Standard Foundation Supplementary Components',
    trade: {
      trade: 'Standard Foundation Supplementary Components',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Standard Foundations',
    value: 'Standard Foundations',
    trade: {
      trade: 'Standard Foundations',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Standard Slabs-on-Grade',
    value: 'Standard Slabs-on-Grade',
    trade: {
      trade: 'Standard Slabs-on-Grade',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Steep Slope Roofing',
    value: 'Steep Slope Roofing',
    trade: { trade: 'Steep Slope Roofing', category: 'Roof' }
  },
  {
    label: 'Stone Facing',
    value: 'Stone Facing',
    trade: { trade: 'Stone Facing', category: 'Ceiling Wall' }
  },
  {
    label: 'Stone Siding',
    value: 'Stone Siding',
    trade: { trade: 'Stone Siding', category: 'Siding' }
  },
  {
    label: 'Storage Specialties',
    value: 'Storage Specialties',
    trade: {
      trade: 'Storage Specialties',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Storm Drainage Piping',
    value: 'Storm Drainage Piping',
    trade: {
      trade: 'Storm Drainage Piping',
      category: 'Utilities'
    }
  },
  {
    label: 'Storm Drainage Ponds and Reservoirs',
    value: 'Storm Drainage Ponds and Reservoirs',
    trade: {
      trade: 'Storm Drainage Ponds and Reservoirs',
      category: 'Utilities'
    }
  },
  {
    label: 'Storm Drainage Pumps',
    value: 'Storm Drainage Pumps',
    trade: {
      trade: 'Storm Drainage Pumps',
      category: 'Utilities'
    }
  },
  {
    label: 'Storm Drainage Utilities',
    value: 'Storm Drainage Utilities',
    trade: {
      trade: 'Storm Drainage Utilities',
      category: 'Utilities'
    }
  },
  {
    label: 'Storm Drainage Utility Connection',
    value: 'Storm Drainage Utility Connection',
    trade: {
      trade: 'Storm Drainage Utility Connection',
      category: 'Utilities'
    }
  },
  {
    label: 'Stormwater Drainage Equipment',
    value: 'Stormwater Drainage Equipment',
    trade: {
      trade: 'Stormwater Drainage Equipment',
      category: 'Plumbing'
    }
  },
  {
    label: 'Stormwater Drainage Piping',
    value: 'Stormwater Drainage Piping',
    trade: {
      trade: 'Stormwater Drainage Piping',
      category: 'Plumbing'
    }
  },
  {
    label: 'Street Sweaping',
    value: 'Street Sweaping',
    trade: { trade: 'Street Sweaping', category: 'Site Prep' }
  },
  {
    label: 'Stress Instrumentation',
    value: 'Stress Instrumentation',
    trade: {
      trade: 'Stress Instrumentation',
      category: 'Special Construction'
    }
  },
  {
    label: 'Structural Slabs-on-Grade',
    value: 'Structural Slabs-on-Grade',
    trade: {
      trade: 'Structural Slabs-on-Grade',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Structure Demolition',
    value: 'Structure Demolition',
    trade: {
      trade: 'Structure Demolition',
      category: 'Demolition'
    }
  },
  {
    label: 'Structure Moving',
    value: 'Structure Moving',
    trade: { trade: 'Structure Moving', category: 'Demolition' }
  },
  {
    label: 'Structure Raising',
    value: 'Structure Raising',
    trade: { trade: 'Structure Raising', category: 'Demolition' }
  },
  {
    label: 'Structure Relocation',
    value: 'Structure Relocation',
    trade: {
      trade: 'Structure Relocation',
      category: 'Demolition'
    }
  },
  {
    label: 'Stucco Siding',
    value: 'Stucco Siding',
    trade: { trade: 'Stucco Siding', category: 'Siding' }
  },
  {
    label: 'Subbase Layer',
    value: 'Subbase Layer',
    trade: {
      trade: 'Subbase Layer',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Subgrade Enclosure Wall Construction',
    value: 'Subgrade Enclosure Wall Construction',
    trade: {
      trade: 'Subgrade Enclosure Wall Construction',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Subgrade Enclosure Wall Interior Skin',
    value: 'Subgrade Enclosure Wall Interior Skin',
    trade: {
      trade: 'Subgrade Enclosure Wall Interior Skin',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Subgrade Enclosure Wall Supplementary Components',
    value: 'Subgrade Enclosure Wall Supplementary Components',
    trade: {
      trade: 'Subgrade Enclosure Wall Supplementary Components',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Subgrade Enclosures',
    value: 'Subgrade Enclosures',
    trade: {
      trade: 'Subgrade Enclosures',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Substructure',
    value: 'Substructure',
    trade: {
      trade: 'Substructure',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Substructure Excavation',
    value: 'Substructure Excavation',
    trade: {
      trade: 'Substructure Excavation',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Substructure Related Activities',
    value: 'Substructure Related Activities',
    trade: {
      trade: 'Substructure Related Activities',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Superstructure',
    value: 'Superstructure',
    trade: { trade: 'Superstructure', category: 'Structure' }
  },
  {
    label: 'Supplementary Components',
    value: 'Supplementary Components',
    trade: {
      trade: 'Supplementary Components',
      category: 'Utilities'
    }
  },
  {
    label: 'Supplementary Components',
    value: 'Supplementary Components',
    trade: {
      trade: 'Supplementary Components',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Supplementary Components',
    value: 'Supplementary Components',
    trade: {
      trade: 'Supplementary Components',
      category: 'Communications'
    }
  },
  {
    label: 'Supply Air',
    value: 'Supply Air',
    trade: { trade: 'Supply Air', category: 'HVAC' }
  },
  {
    label: 'Supply Services',
    value: 'Supply Services',
    trade: { trade: 'Supply Services', category: 'Supplier' }
  },
  {
    label: 'Suspended Ceiling Construction',
    value: 'Suspended Ceiling Construction',
    trade: {
      trade: 'Suspended Ceiling Construction',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Suspended Plaster and Gypsum Board Ceilings',
    value: 'Suspended Plaster and Gypsum Board Ceilings',
    trade: {
      trade: 'Suspended Plaster and Gypsum Board Ceilings',
      category: 'Interior Construction'
    }
  },
  {
    label: 'Suspended Scaffolding',
    value: 'Suspended Scaffolding',
    trade: {
      trade: 'Suspended Scaffolding',
      category: 'Scaffolding'
    }
  },
  {
    label: 'Tape Only',
    value: 'Tape Only',
    trade: { trade: 'Tape Only', category: 'Ceiling Wall' }
  },
  {
    label: 'Teller and Service Equipment',
    value: 'Teller and Service Equipment',
    trade: {
      trade: 'Teller and Service Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Temporary Electric Service',
    value: 'Temporary Electric Service',
    trade: {
      trade: 'Temporary Electric Service',
      category: 'Site Prep'
    }
  },
  {
    label: 'Temporary Utilities',
    value: 'Temporary Utilities',
    trade: { trade: 'Temporary Utilities', category: 'Site Prep' }
  },
  {
    label: 'Temporary Water Service',
    value: 'Temporary Water Service',
    trade: {
      trade: 'Temporary Water Service',
      category: 'Site Prep'
    }
  },
  {
    label: 'Terrazzo Flooring',
    value: 'Terrazzo Flooring',
    trade: {
      trade: 'Terrazzo Flooring',
      category: 'Floors Stairs'
    }
  },
  {
    label: 'Terrazzo Stair Finish',
    value: 'Terrazzo Stair Finish',
    trade: {
      trade: 'Terrazzo Stair Finish',
      category: 'Floors Stairs'
    }
  },
  {
    label: 'Theater and Stage Equipment',
    value: 'Theater and Stage Equipment',
    trade: {
      trade: 'Theater and Stage Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Thermal Cooling Storage',
    value: 'Thermal Cooling Storage',
    trade: { trade: 'Thermal Cooling Storage', category: 'HVAC' }
  },
  {
    label: 'Thermal Decontamination',
    value: 'Thermal Decontamination',
    trade: {
      trade: 'Thermal Decontamination',
      category: 'Remediation'
    }
  },
  {
    label: 'Thermal Heat Storage',
    value: 'Thermal Heat Storage',
    trade: { trade: 'Thermal Heat Storage', category: 'HVAC' }
  },
  {
    label: 'Tile\tTile Flooring',
    value: 'Tile\tTile Flooring',
    trade: { trade: 'Tile\tTile Flooring', category: 'Supplier' }
  },
  {
    label: 'Tile Stair Finish',
    value: 'Tile Stair Finish',
    trade: { trade: 'Tile Stair Finish', category: 'Supplier' }
  },
  {
    label: 'Tile Wall Finish',
    value: 'Tile Wall Finish',
    trade: { trade: 'Tile Wall Finish', category: 'Supplier' }
  },
  {
    label: 'Tile Wall Finish',
    value: 'Tile Wall Finish',
    trade: { trade: 'Tile Wall Finish', category: 'Ceiling Wall' }
  },
  {
    label: 'Toilet, Bath, and Laundry Accessories',
    value: 'Toilet, Bath, and Laundry Accessories',
    trade: {
      trade: 'Toilet, Bath, and Laundry Accessories',
      category: 'Plumbing'
    }
  },
  {
    label: 'Tower Demolition',
    value: 'Tower Demolition',
    trade: { trade: 'Tower Demolition', category: 'Demolition' }
  },
  {
    label: 'Towers',
    value: 'Towers',
    trade: { trade: 'Towers', category: 'Special Construction' }
  },
  {
    label: 'Traffic Bearing Coatings',
    value: 'Traffic Bearing Coatings',
    trade: { trade: 'Traffic Bearing Coatings', category: 'Roof' }
  },
  {
    label: 'Traffic Bearing Horizontal Enclosures',
    value: 'Traffic Bearing Horizontal Enclosures',
    trade: {
      trade: 'Traffic Bearing Horizontal Enclosures',
      category: 'Roof'
    }
  },
  {
    label: 'Transfer Switches',
    value: 'Transfer Switches',
    trade: { trade: 'Transfer Switches', category: 'Electric' }
  },
  {
    label: 'Transient Voltage Suppression',
    value: 'Transient Voltage Suppression',
    trade: {
      trade: 'Transient Voltage Suppression',
      category: 'Electric'
    }
  },
  {
    label: 'Transportation and Disposal of Hazardous Materials',
    value: 'Transportation and Disposal of Hazardous Materials',
    trade: {
      trade: 'Transportation and Disposal of Hazardous Materials',
      category: 'Remediation'
    }
  },
  {
    label: 'Tree and Shrub Removal and Trimming',
    value: 'Tree and Shrub Removal and Trimming',
    trade: {
      trade: 'Tree and Shrub Removal and Trimming',
      category: 'Site Prep'
    }
  },
  {
    label: 'Trim Painting',
    value: 'Trim Painting',
    trade: { trade: 'Trim Painting', category: 'Paint' }
  },
  {
    label: 'Trusses',
    value: 'Trusses',
    trade: { trade: 'Trusses', category: 'Structure' }
  },
  {
    label: 'Tunnel Construction Related Activities',
    value: 'Tunnel Construction Related Activities',
    trade: {
      trade: 'Tunnel Construction Related Activities',
      category: 'Special Construction'
    }
  },
  {
    label: 'Tunnels',
    value: 'Tunnels',
    trade: { trade: 'Tunnels', category: 'Special Construction' }
  },
  {
    label: 'Turf and Grasses',
    value: 'Turf and Grasses',
    trade: { trade: 'Turf and Grasses', category: 'Landscape' }
  },
  {
    label: 'Turntables',
    value: 'Turntables',
    trade: { trade: 'Turntables', category: 'Conveying' }
  },
  {
    label: 'Underground Utility Services',
    value: 'Underground Utility Services',
    trade: {
      trade: 'Underground Utility Services',
      category: 'Utilities'
    }
  },
  {
    label: 'Underpinning',
    value: 'Underpinning',
    trade: {
      trade: 'Underpinning',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Underslab Drainage',
    value: 'Underslab Drainage',
    trade: {
      trade: 'Underslab Drainage',
      category: 'Substructure Mitigation'
    }
  },
  {
    label: 'Unit Kitchens',
    value: 'Unit Kitchens',
    trade: { trade: 'Unit Kitchens', category: 'Equipment' }
  },
  {
    label: 'Utility Demolition',
    value: 'Utility Demolition',
    trade: { trade: 'Utility Demolition', category: 'Site Prep' }
  },
  {
    label: 'Utility Relocation',
    value: 'Utility Relocation',
    trade: { trade: 'Utility Relocation', category: 'Site Prep' }
  },
  {
    label: 'Utility Septic Tanks',
    value: 'Utility Septic Tanks',
    trade: {
      trade: 'Utility Septic Tanks',
      category: 'Utilities'
    }
  },
  {
    label: 'Utility Services',
    value: 'Utility Services',
    trade: { trade: 'Utility Services', category: 'Utilities' }
  },
  {
    label: 'Vacuum Systems',
    value: 'Vacuum Systems',
    trade: { trade: 'Vacuum Systems', category: 'Plumbing' }
  },
  {
    label: 'Vapor Retarder',
    value: 'Vapor Retarder',
    trade: {
      trade: 'Vapor Retarder',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Vault Equipment',
    value: 'Vault Equipment',
    trade: { trade: 'Vault Equipment', category: 'Equipment' }
  },
  {
    label: 'Vehicle Fare Collection',
    value: 'Vehicle Fare Collection',
    trade: {
      trade: 'Vehicle Fare Collection',
      category: 'Roadway'
    }
  },
  {
    label: 'Vehicle Servicing Equipment',
    value: 'Vehicle Servicing Equipment',
    trade: {
      trade: 'Vehicle Servicing Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Vehicle and Pedestrian Equipment',
    value: 'Vehicle and Pedestrian Equipment',
    trade: {
      trade: 'Vehicle and Pedestrian Equipment',
      category: 'Equipment'
    }
  },
  {
    label: 'Vehicular Tunnels',
    value: 'Vehicular Tunnels',
    trade: {
      trade: 'Vehicular Tunnels',
      category: 'Special Construction'
    }
  },
  {
    label: 'Ventilation',
    value: 'Ventilation',
    trade: { trade: 'Ventilation', category: 'HVAC' }
  },
  {
    label: 'Ventilation Supplementary Components',
    value: 'Ventilation Supplementary Components',
    trade: {
      trade: 'Ventilation Supplementary Components',
      category: 'HVAC'
    }
  },
  {
    label: 'Vents and Hatches',
    value: 'Vents and Hatches',
    trade: { trade: 'Vents and Hatches', category: 'Roof' }
  },
  {
    label: 'Vertical Conveying Systems',
    value: 'Vertical Conveying Systems',
    trade: {
      trade: 'Vertical Conveying Systems',
      category: 'Conveying'
    }
  },
  {
    label: 'Video Surveillance',
    value: 'Video Surveillance',
    trade: {
      trade: 'Video Surveillance',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Vinyl Siding',
    value: 'Vinyl Siding',
    trade: { trade: 'Vinyl Siding', category: 'Siding' }
  },
  {
    label: 'Voice Communications',
    value: 'Voice Communications',
    trade: {
      trade: 'Voice Communications',
      category: 'Communications'
    }
  },
  {
    label: 'Voice Communications Messaging',
    value: 'Voice Communications Messaging',
    trade: {
      trade: 'Voice Communications Messaging',
      category: 'Communications'
    }
  },
  {
    label: 'Voice Communications Switching and Routing Equipment',
    value: 'Voice Communications Switching and Routing Equipment',
    trade: {
      trade: 'Voice Communications Switching and Routing Equipment',
      category: 'Communications'
    }
  },
  {
    label: 'Voice Communications Terminal Equipment',
    value: 'Voice Communications Terminal Equipment',
    trade: {
      trade: 'Voice Communications Terminal Equipment',
      category: 'Communications'
    }
  },
  {
    label: 'Wall Carpeting',
    value: 'Wall Carpeting',
    trade: { trade: 'Wall Carpeting', category: 'Ceiling Wall' }
  },
  {
    label: 'Wall Coverings',
    value: 'Wall Coverings',
    trade: { trade: 'Wall Coverings', category: 'Ceiling Wall' }
  },
  {
    label: 'Wall Finish Supplementary Components',
    value: 'Wall Finish Supplementary Components',
    trade: {
      trade: 'Wall Finish Supplementary Components',
      category: 'Ceiling Wall'
    }
  },
  {
    label: 'Wall Finishes',
    value: 'Wall Finishes',
    trade: { trade: 'Wall Finishes', category: 'Ceiling Wall' }
  },
  {
    label: 'Wall Foundations',
    value: 'Wall Foundations',
    trade: {
      trade: 'Wall Foundations',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Wall Painting and Coating',
    value: 'Wall Painting and Coating',
    trade: {
      trade: 'Wall Painting and Coating',
      category: 'Paint'
    }
  },
  {
    label: 'Wall Paneling',
    value: 'Wall Paneling',
    trade: { trade: 'Wall Paneling', category: 'Ceiling Wall' }
  },
  {
    label: 'Wall and Door Protection',
    value: 'Wall and Door Protection',
    trade: {
      trade: 'Wall and Door Protection',
      category: 'Special Interior'
    }
  },
  {
    label: 'Walls for Subgrade Enclosures',
    value: 'Walls for Subgrade Enclosures',
    trade: {
      trade: 'Walls for Subgrade Enclosures',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Waste Management',
    value: 'Waste Management',
    trade: { trade: 'Waste Management', category: 'Garbage' }
  },
  {
    label: 'Waste Management - Waste Collection - Dumpster',
    value: 'Waste Management - Waste Collection - Dumpster',
    trade: {
      trade: 'Waste Management - Waste Collection - Dumpster',
      category: 'Garbage'
    }
  },
  {
    label: 'Waste Management Services',
    value: 'Waste Management Services',
    trade: {
      trade: 'Waste Management Services',
      category: 'Garbage'
    }
  },
  {
    label: 'Water Intrusion Detection and Alarm',
    value: 'Water Intrusion Detection and Alarm',
    trade: {
      trade: 'Water Intrusion Detection and Alarm',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Water Remediation',
    value: 'Water Remediation',
    trade: { trade: 'Water Remediation', category: 'Remediation' }
  },
  {
    label: 'Water Utilities',
    value: 'Water Utilities',
    trade: { trade: 'Water Utilities', category: 'Utilities' }
  },
  {
    label: 'Water Utility',
    value: 'Water Utility',
    trade: { trade: 'Water Utility', category: 'Utilities' }
  },
  {
    label: 'Water and Gas Mitigation',
    value: 'Water and Gas Mitigation',
    trade: {
      trade: 'Water and Gas Mitigation',
      category: 'Substructure Mitigation'
    }
  },
  {
    label: 'Water-Based Fire-Suppression',
    value: 'Water-Based Fire-Suppression',
    trade: {
      trade: 'Water-Based Fire-Suppression',
      category: 'Detection Alarm'
    }
  },
  {
    label: 'Waterproofing',
    value: 'Waterproofing',
    trade: {
      trade: 'Waterproofing',
      category: 'Foundation Substructure'
    }
  },
  {
    label: 'Wear Surfaces',
    value: 'Wear Surfaces',
    trade: { trade: 'Wear Surfaces', category: 'Roof' }
  },
  {
    label: 'Wetlands',
    value: 'Wetlands',
    trade: { trade: 'Wetlands', category: 'Earthwork' }
  },
  {
    label: 'Wind Turbine Service',
    value: 'Wind Turbine Service',
    trade: {
      trade: 'Wind Turbine Service',
      category: 'Utilities'
    }
  },
  {
    label: 'Window Treatments',
    value: 'Window Treatments',
    trade: { trade: 'Window Treatments', category: 'Furnishings' }
  },
  {
    label: 'Windows',
    value: 'Windows',
    trade: { trade: 'Windows', category: 'Glass' }
  },
  {
    label: 'Windows, Glass, and Glazing Contracting',
    value: 'Windows, Glass, and Glazing Contracting',
    trade: {
      trade: 'Windows, Glass, and Glazing Contracting',
      category: 'Glass'
    }
  },
  {
    label: 'Wireless Communications Distribution',
    value: 'Wireless Communications Distribution',
    trade: {
      trade: 'Wireless Communications Distribution',
      category: 'Communications'
    }
  },
  {
    label: 'Wiring Devices',
    value: 'Wiring Devices',
    trade: { trade: 'Wiring Devices', category: 'Electric' }
  },
  {
    label: 'Wood Flooring',
    value: 'Wood Flooring',
    trade: { trade: 'Wood Flooring', category: 'Floors Stairs' }
  },
  {
    label: 'Wood Paneling',
    value: 'Wood Paneling',
    trade: { trade: 'Wood Paneling', category: 'Ceiling Wall' }
  },
  {
    label: 'Wood Siding',
    value: 'Wood Siding',
    trade: { trade: 'Wood Siding', category: 'Siding' }
  },
  {
    label: 'Wood Stair Finish',
    value: 'Wood Stair Finish',
    trade: {
      trade: 'Wood Stair Finish',
      category: 'Floors Stairs'
    }
  }
];
