import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { FullBid, } from 'manybuildlogic';
import { AppState } from '../../Redux';

const BidListItem = (bid: FullBid) => (
  <TableRow key={bid.id}>
    <TableCell component="th" scope="row">
      <Link to={'/bid/' + bid.id}>{bid.projectName}</Link>
    </TableCell>
    <TableCell align="right">{bid.trades.join(', ')}</TableCell>
    <TableCell align="right">${bid.bidAmt}</TableCell>
    <TableCell align="right">
      {bid.materialIncluded ? <Trans>Yes</Trans> : <Trans>No</Trans>}
    </TableCell>
    <TableCell align="right">{bid.approved ? <Trans>Yes</Trans> : <Trans>No</Trans>}</TableCell>
  </TableRow>
);

const mapStateToProps = (state: AppState) => ({
  bids: state.Bid.bids,
});
const connector = connect(mapStateToProps, {});
type UserBidListProps = ConnectedProps<typeof connector>;

const UserBidList: React.FC<UserBidListProps> = props => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>
          <Trans>Project</Trans>
        </TableCell>
        <TableCell align="right">
          <Trans>Trades</Trans>
        </TableCell>
        <TableCell align="right">
          <Trans>Bid Amount</Trans>
        </TableCell>
        <TableCell align="right">
          <Trans>Material Included in Bid</Trans>
        </TableCell>
        <TableCell align="right">
          <Trans>Approved</Trans>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>{props.bids.map(bid => BidListItem(bid))}</TableBody>
  </Table>
);

export default connector(UserBidList);
