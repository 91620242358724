import {
  UserActionTypes,
  UPDATE_USER,
  CLEAR_USER,
  UserState,
  ADD_USER,
  REPORT_AUTH_STATUS,
  SET_LANGUAGE,
} from '../Store/User';
import { BlankUser } from 'manybuildlogic';

const initialState: UserState = {
  user: BlankUser,
  signedIn: false,
  authStateReported: false,
  lang: 'en',
};

export function userReducer(state = initialState, action: UserActionTypes): UserState {
  switch (action.type) {
    case ADD_USER:
      return {
        user: action.user,
        signedIn: true,
        authStateReported: true,
        lang: action.user.language,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action.user,
      };
    case CLEAR_USER:
      return {
        ...initialState,
        authStateReported: true,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        lang: action.lang,
      };
    case REPORT_AUTH_STATUS:
      return {
        ...state,
        authStateReported: action.status,
      };
    default:
      return state;
  }
}
