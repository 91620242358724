import { ADD_MESSAGES, UPDATE_MESSAGE } from './../Store/Chat';
import {
  LOAD_MESSAGES,
  ADD_MESSAGE,
  ChatActionTypes,
  LOAD_CHATS,
  ADD_CHAT,
  UPDATE_CHAT,
  DELETE_CHAT,
  CLEAR_CHATS,
} from '../Store/Chat';
import { Chat, Message } from 'manybuildlogic';

export function LoadChats(chats: Chat[]): ChatActionTypes {
  return {
    type: LOAD_CHATS,
    chats,
  };
}

export function AddChat(chat: Chat): ChatActionTypes {
  return {
    type: ADD_CHAT,
    chat,
  };
}

export function UpdateChat(chat: Chat): ChatActionTypes {
  return {
    type: UPDATE_CHAT,
    chat,
  };
}

export function DeleteChat(id: string): ChatActionTypes {
  return {
    type: DELETE_CHAT,
    id,
  };
}

export function ClearChats(): ChatActionTypes {
  return {
    type: CLEAR_CHATS,
  };
}

export function LoadMessages(id: string, messages: Message[]): ChatActionTypes {
  return {
    type: LOAD_MESSAGES,
    id,
    messages,
  };
}

export function AddMessage(id: string, message: Message): ChatActionTypes {
  return {
    type: ADD_MESSAGE,
    id,
    message,
  };
}

export function AddMessages(id: string, messages: Message[]): ChatActionTypes {
  return {
    type: ADD_MESSAGES,
    id,
    messages,
  };
}

export function UpdateMessage(chat: string, message: Message): ChatActionTypes {
  return {
    type: UPDATE_MESSAGE,
    chat,
    message,
  };
}
