import React from 'react';

class PrivacyAndTerms extends React.Component {
  public render() {
    return (
      <div
        id="infoLinksContainer"
        style={{
          display: 'inline-block',
          fontSize: '13px',
          position: 'absolute',
          bottom: '0px',
          right: '0px',
          left: '0px',
          paddingBottom: '12px',
        }}
      >
        <a
          href="https://manybuildsolutions.com/privacy"
          style={{ color: 'white', textDecoration: 'none', margin: '0px 5px' }}
        >
          Privacy Policy
        </a>
        <a
          href="https://manybuildsolutions.com/termsofuse"
          style={{ color: 'white', textDecoration: 'none', margin: '0px 5px' }}
        >
          Terms of Use
        </a>
      </div>
    );
  }
}

export default PrivacyAndTerms;
