import url from 'url';
import { request } from 'http';

export const CheckResponseStatus: (target: string) => Promise<boolean> = (target: string) =>
  new Promise((resolve, reject) => {
    let uri;
    try {
      uri = url.parse(target);
    } catch (err) {
      reject(new Error(`Invalid url ${target}`));
    }

    if (uri) {
      const options = {
        method: 'HEAD',
        host: uri.host,
        protocol: uri.protocol,
        port: uri.port,
        path: uri.path,
        timeout: 5 * 1000,
      };

      const req = request(options, res => {
        const { statusCode } = res;

        if (statusCode && statusCode >= 200 && statusCode < 300) {
          resolve(true);
        } else {
          resolve(false);
        }
      });

      req.on('error', reject);
    } else {
      reject(new Error(`Invalid url ${target}`));
    }
  });
