import { FullProject, DisplayProject } from 'manybuildlogic';

export interface ProjectState {
  projectIds: string[];
  projectsById: {
    [key: string]: FullProject;
  };
  idMap: {
    [key: string]: string;
  };
  lastUpdated: number;
  marketProjectsById: {
    [key: string]: DisplayProject;
  }
  marketProjectIds: string[]
}

export const LOAD_PROJECTS = 'LOAD_PROJECTS';
export const ADD_PROJECT = 'ADD_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS';
export const LOAD_MARKET_PROJECTS = 'LOAD_MARKET_PROJECTS';
export const CLEAR_MARKET_PROJECTS = 'CLEAR_MARKET_PROJECTS';

interface LoadMarketProjectsAction {
  type: typeof LOAD_MARKET_PROJECTS;
  projects: DisplayProject[];
}

interface ClearMaretProjectsAction {
  type: typeof CLEAR_MARKET_PROJECTS;
} 

interface LoadProjectsAction {
  type: typeof LOAD_PROJECTS;
  projects: FullProject[];
}

interface AddProjectAction {
  type: typeof ADD_PROJECT;
  project: FullProject;
}

interface UpdateProjectAction {
  type: typeof UPDATE_PROJECT;
  project: Partial<FullProject>;
  id: string;
}

interface DeleteProjectAction {
  type: typeof DELETE_PROJECT;
  id: string;
}

interface ClearProjectsAction {
  type: typeof CLEAR_PROJECTS;
}

export type ProjectActionTypes =
  | LoadProjectsAction
  | AddProjectAction
  | UpdateProjectAction
  | DeleteProjectAction
  | ClearProjectsAction
  | LoadMarketProjectsAction
  | ClearMaretProjectsAction;
