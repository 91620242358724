import { FullUserData, SupportedLang } from 'manybuildlogic';

export interface UserState {
  user: FullUserData;
  signedIn: boolean;
  authStateReported: boolean;
  lang: SupportedLang;
}

export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const REPORT_AUTH_STATUS = 'REPORT_AUTH_STATUS';
export const SET_LANGUAGE = 'SET_LANGUAGE';

interface AddUserAction {
  type: typeof ADD_USER;
  user: FullUserData;
}

interface SetLanguageAction {
  type: typeof SET_LANGUAGE;
  lang: SupportedLang;
}

interface UpdateUserAction {
  type: typeof UPDATE_USER;
  user: FullUserData;
}

interface ReportAuthStatusAction {
  type: typeof REPORT_AUTH_STATUS;
  status: boolean;
}

interface ClearUserAction {
  type: typeof CLEAR_USER;
}

export type UserActionTypes =
  | UpdateUserAction
  | ClearUserAction
  | AddUserAction
  | ReportAuthStatusAction
  | SetLanguageAction;
