import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nProvider } from '@lingui/react';
import Routes from '../Routes';
import en from '../../locales/en/messages';
import es from '../../locales/es/messages';
import './App.css';
import { AppState } from '../../Redux';
import { connect, ConnectedProps } from 'react-redux';

const mapStateToProps = (state: AppState) => state.Auth;
const connector = connect(mapStateToProps, {});
type AppProps = ConnectedProps<typeof connector>;

const catalogs = { en, es };
const defaultLang = window.location.href.includes('trabajos') ? 'es' : 'en';

const App: React.FC<AppProps> = (props: AppProps) => (
  <div id="app_container">
    <I18nProvider language={props.signedIn ? props.user.language : defaultLang} catalogs={catalogs}>
      <BrowserRouter>
        <Routes isAuthenticated={props.signedIn} isAdmin={props.user.admin} />
      </BrowserRouter>
    </I18nProvider>
  </div>
);

export default connector(App);
