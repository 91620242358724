import React from 'react';
import { Switch, Route } from 'react-router-dom';
import UserPage, { SignInPage, SignUpPage } from '../User';
import HomePage from '../Home';
import { ProjectPage, NewProjectIFrame } from '../Project';
import DetailBidPage from '../Bid';
import PrivateRoute from './PrivateRoute';
import SetPasswordPage, { ChangePasswordPage } from '../User/SetPassword';
import TypeToSearchDemo from '../Demos/TypeToSearch';
import { ConnectedComponent } from 'react-redux';
import ReferralPage from '../ReferralPage';

interface RoutesProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
}

const DefaultProtectedRoutesProps = {
  authenticationPath: '/sign-in',
  isAllowed: true,
  restrictedPath: '/',
};

const PrivateRoutes = ['/new-project', '/bid/:bidId', '/user', '/change-password', 'set-password'];

const PrivateRouteComponents: Record<
  string,
  React.ComponentType<any> | ConnectedComponent<any, any>
> = {
  '/new-project': NewProjectIFrame,
  '/bid/:bidId': DetailBidPage,
  '/user': UserPage,
  '/change-password': ChangePasswordPage,
  '/set-password': SetPasswordPage,
};

const Routes: React.FC<RoutesProps> = props => (
  <Switch>
    <Route exact path="/" component={HomePage} />
    <Route path="/sign-in" component={SignInPage} />
    <Route path="/sign-up" component={SignUpPage} />
    <Route path="/project/:projectId" component={ProjectPage} />
    <Route path="/typetosearch-demo" component={TypeToSearchDemo} />
    <Route path="/referral" component={ReferralPage} />
    {PrivateRoutes.map(r => (
      <PrivateRoute
        key={r}
        path={r}
        component={PrivateRouteComponents[r]}
        isAuthenticated={props.isAuthenticated}
        {...DefaultProtectedRoutesProps}
      />
    ))}
  </Switch>
);

export default Routes;
