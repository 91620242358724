import React from 'react';
import { I18n } from '@lingui/react';
import { t } from '@lingui/macro';
import Page from '../Page';
import Market from '../Market/Market';

const HomePage: React.FC = () => (
  <I18n>
    {({ i18n }) => (
      <Page title={i18n._(t`Explore Projects`)}>
        <Market />
      </Page>
    )}
  </I18n>
);

export default HomePage;
