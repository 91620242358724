import { FullBid } from 'manybuildlogic';

export interface BidState {
  bids: FullBid[];
}

export const LOAD_BIDS = 'LOAD_BIDS';
export const ADD_BID = 'ADD_BID';
export const UPDATE_BID = 'UPDATE_BID';
export const DELETE_BID = 'DELETE_BID';
export const CLEAR_BIDS = 'CLEAR_BIDS';

interface LoadBidAction {
  type: typeof LOAD_BIDS;
  bids: FullBid[];
}

interface AddBidAction {
  type: typeof ADD_BID;
  bid: FullBid;
}

interface UpdateBidAction {
  type: typeof UPDATE_BID;
  bid: FullBid;
}

interface DeleteBidAction {
  type: typeof DELETE_BID;
  id: string;
}

interface ClearBidAction {
  type: typeof CLEAR_BIDS;
}

export type BidActionTypes =
  | LoadBidAction
  | AddBidAction
  | UpdateBidAction
  | DeleteBidAction
  | ClearBidAction;
