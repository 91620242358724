import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import Mixpanel from '../Mixpanel';
import {
  FullUserData,
  UserData,
  FullBid,
  FullProject,
  ConstructionType,
  Chat,
  Message,
} from 'manybuildlogic';
import { FirebaseProps } from './FirebaseContext';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

class Firebase {
  public auth: firebase.auth.Auth;
  public firestore: firebase.firestore.Firestore;
  public storage: firebase.storage.Storage;
  public functions: firebase.functions.Functions;
  public authStateReported: boolean;
  public reduxActions: FirebaseProps;

  constructor(props: FirebaseProps) {
    process.env.NODE_ENV === 'development'
      ? console.log('Connected to alpha')
      : console.log('Connected live');
    firebase.initializeApp(firebaseConfig);
    this.auth = firebase.auth();
    this.firestore = firebase.firestore();
    this.storage = firebase.storage();
    this.functions = firebase.functions();

    this.reduxActions = props;
    this.authStateReported = false;
    this.getUser = this.getUser.bind(this);
    this.getByQuery = this.getByQuery.bind(this);
    this.authStateListener = this.authStateListener.bind(this);
    this.auth.onAuthStateChanged(this.authStateListener);
  }

  public getCounter = (id: ConstructionType) =>
    this.firestore
      .collection('constants')
      .doc(id)
      .get()
      .then(doc => {
        const data = doc.data();
        if (data && data.count) {
          return data.count as number;
        }

        throw Error;
      });

  public incrementCounter = (id: ConstructionType) =>
    this.firestore
      .collection('constants')
      .doc(id)
      .update('count', firebase.firestore.FieldValue.increment(1));

  public getQY: () => Promise<string> = () =>
    this.firestore
      .collection('constants')
      .doc('Quarter')
      .get()
      .then(doc => {
        const data = doc.data();
        if (data && data.count) {
          return this.firestore
            .collection('constants')
            .doc('Year')
            .get()
            .then(docTwo => {
              const dataTwo = docTwo.data();
              if (dataTwo && dataTwo.count) {
                return data.count.toString() + dataTwo.count.toString();
              }

              throw Error;
            });
        }
      });

  public authStateListener(user: firebase.User | null) {
    if (user) {
      const { uid } = user;
      Mixpanel.init();
      Mixpanel.identify(uid);

      if (user.metadata.creationTime === user.metadata.lastSignInTime && this.authStateReported) {
        Mixpanel.track('New user', { id: uid });
      } else {
        this.authStateReported = true;
        this.getUser(uid)
          .then(u => {
            this.reduxActions.AddUser({ ...u, id: uid });
            this.getByQuery('projects', 'ownerId', '==', uid)
              .then(data => this.reduxActions.LoadProjects(data as FullProject[]))
              .catch(err => console.log(err));
            this.getByQuery('bids', 'ownerId', '==', uid)
              .then(data => this.reduxActions.LoadBids(data as FullBid[]))
              .catch(err => console.log(err));
          })
          .catch(err => console.log(err));
      }
    } else {
      this.authStateReported = true;
      this.reduxActions.ClearUser();
      this.reduxActions.ClearProjects();
      this.reduxActions.ClearBids();
    }
  }

  public doCreateUserWithEmailAndPassword = (email: string, password: string) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  public doSignInWithEmailAndPassword = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password);

  public doSignOut = () => this.auth.signOut();

  public doPasswordReset = (email: string) => this.auth.sendPasswordResetEmail(email);

  public doPasswordUpdate = (password: string) =>
    this.auth.currentUser
      ? this.auth.currentUser.updatePassword(password)
      : new Promise<void>((res, rej) => rej());

  public addUser = (user: UserData, uid: string) => {
    this.authStateReported = true;
    return this.firestore
      .collection('users')
      .doc(uid)
      .set({ ...user, id: uid });
  };

  public setUser = (user: FullUserData) =>
    this.firestore
      .collection('users')
      .doc(user.id)
      .update(user);

  public updateUser = (user: Partial<FullUserData>) =>
    this.firestore
      .collection('users')
      .doc(user.id)
      .update(user);

  public getUser = (uid: string) =>
    this.firestore
      .collection('users')
      .doc(uid)
      .get()
      .then(doc => doc.data() as FullUserData);

  public createBid = (bid: FullBid) =>
    this.firestore
      .collection('bids')
      .doc(bid.id)
      .set(bid);

  public updateBid = (bid: FullBid) =>
    this.firestore
      .collection('bids')
      .doc(bid.id)
      .update(bid);

  public getBid = (id: string) =>
    this.firestore
      .collection('bids')
      .doc(id)
      .get()
      .then(doc => doc.data() as FullBid);

  public deleteBid = (id: string) =>
    this.firestore
      .collection('bids')
      .doc(id)
      .delete();

  public createProject = (project: FullProject) =>
    this.firestore
      .collection('projects')
      .doc(project.id)
      .set(project);

  public updateProject = (project: FullProject) =>
    this.firestore
      .collection('projects')
      .doc(project.id)
      .update(project);

  public getProject = (id: string) =>
    this.firestore
      .collection('projects')
      .doc(id)
      .get()
      .then(doc => doc.data() as FullProject);

  public deleteProject = (id: string) =>
    this.firestore
      .collection('projects')
      .doc(id)
      .delete();

  public getByQuery = (
    collection: string,
    fieldQuery: string,
    opStr: firebase.firestore.WhereFilterOp,
    val: any
  ) =>
    this.firestore
      .collection(collection)
      .where(fieldQuery, opStr, val)
      .get()
      .then(snap => snap.docs.map(doc => doc.data()));

  public createChat = (chat: Chat) =>
    this.firestore
      .collection('chats')
      .doc(chat.id)
      .set(chat);

  public updateChat = (chat: Chat) => this.createChat(chat);

  public deleteChat = (id: string) =>
    this.firestore
      .collection('chats')
      .doc(id)
      .delete();

  public getChat = (id: string) =>
    this.firestore
      .collection('chats')
      .doc(id)
      .get()
      .then(doc => doc.data() as Chat);

  public sendMessage = (chat: string, message: Message) =>
    this.firestore
      .collection('chats')
      .doc(chat)
      .collection('messages')
      .doc(message.id)
      .set(message);

  public updateMessage = (chat: string, message: Message) => this.sendMessage(chat, message);

  public deleteMessage = (chat: string, id: string) =>
    this.firestore
      .collection('chats')
      .doc(chat)
      .collection('messages')
      .doc(id)
      .delete();

  public getMessage = (chat: string, id: string) =>
    this.firestore
      .collection('chats')
      .doc(chat)
      .collection('messages')
      .doc(id)
      .get()
      .then(doc => doc.data() as Message);
}

export default Firebase;
