import { Message, Chat } from 'manybuildlogic';

export interface ChatState {
  chats: Chat[];
}

export const LOAD_CHATS = 'LOAD_CHATS';
export const ADD_CHAT = 'ADD_CHAT';
export const UPDATE_CHAT = 'UPDATE_CHAT';
export const DELETE_CHAT = 'DELETE_CHAT';
export const CLEAR_CHATS = 'CLEAR_CHATS';
export const LOAD_MESSAGES = 'LOAD_MESSAGES';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const ADD_MESSAGES = 'ADD_MESSAGES';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';

interface LoadChatAction {
  type: typeof LOAD_CHATS;
  chats: Chat[];
}

interface AddChatAction {
  type: typeof ADD_CHAT;
  chat: Chat;
}

interface UpdateChatAction {
  type: typeof UPDATE_CHAT;
  chat: Chat;
}

interface DeleteChatAction {
  type: typeof DELETE_CHAT;
  id: string;
}

interface ClearChatAction {
  type: typeof CLEAR_CHATS;
}

interface AddMessageAction {
  type: typeof ADD_MESSAGE;
  id: string;
  message: Message;
}

interface AddMessagesAction {
  type: typeof ADD_MESSAGES;
  id: string;
  messages: Message[];
}

interface LoadMessagesAction {
  type: typeof LOAD_MESSAGES;
  id: string;
  messages: Message[];
}

interface UpdateMessageAction {
  type: typeof UPDATE_MESSAGE;
  chat: string;
  message: Message;
}

export type ChatActionTypes =
  | LoadChatAction
  | AddChatAction
  | UpdateChatAction
  | DeleteChatAction
  | ClearChatAction
  | LoadMessagesAction
  | AddMessageAction
  | AddMessagesAction
  | UpdateMessageAction;
